import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import { RouteWithSubRoutes } from './HelperComponents/RouteWithSubRoutes';
import { routes } from './routes';

class App extends Component {
  render() {
    return (
      <Router>
        <div className="app">
          {routes.map((route, index) => (
            <RouteWithSubRoutes {...route} key={index} serviceContext={this.serviceContext} />
          ))}
        </div>
      </Router>
    );
  }
}

export default App;
