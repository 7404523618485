import React from 'react';
import { List, Button, Popup, Header } from 'semantic-ui-react';

import { Config } from '../../../Config';

export const Color = {
  BLACK: '#000000',
  BLUE: '#2b83ba',
  CYAN: '#16b08d',
  GREEN: '#00de5e',
  GREY: '#c2c2c2',
  MAGENTA: '#c00d96',
  ORANGE: '#fd8700',
  PURPLE: '#8400ea',
  RED: '#fc1942',
  WHITE: '#ffffff',
  YELLOW: '#ffff00',
};

export const Legend = () => (
  <Popup
    trigger={<Button icon="map signs" circular color="blue" className="contrast" />}
    position="bottom center"
    flowing
    style={Config.popupStyle}>
    <Header>Legend</Header>
    <List className="legend">
      <List.Item>
        <List.Content>
          <svg className="legend-circle">
            <circle cx="50%" cy="50%" r="50%" fill={Color.BLUE} />
          </svg>
          less than 1
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Content>
          <svg className="legend-circle">
            <circle cx="50%" cy="50%" r="50%" fill={Color.CYAN} />
          </svg>
          less than 3
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Content>
          <svg className="legend-circle">
            <circle cx="50%" cy="50%" r="50%" fill={Color.GREEN} />
          </svg>
          less than 5
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Content>
          <svg className="legend-circle">
            <circle cx="50%" cy="50%" r="50%" fill={Color.YELLOW} />
          </svg>
          less than 7
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Content>
          <svg className="legend-circle">
            <circle cx="50%" cy="50%" r="50%" fill={Color.ORANGE} />
          </svg>
          less than 9
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Content>
          <svg className="legend-circle">
            <circle cx="50%" cy="50%" r="50%" fill={Color.RED} />
          </svg>
          less than 11
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Content>
          <svg className="legend-circle">
            <circle cx="50%" cy="50%" r="50%" fill={Color.MAGENTA} />
          </svg>
          less than 13
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Content>
          <svg className="legend-circle">
            <circle cx="50%" cy="50%" r="50%" fill={Color.PURPLE} />
          </svg>
          13 and higher
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Content>
          <svg className="legend-circle">
            <circle cx="50%" cy="50%" r="50%" fill={Color.WHITE} />
          </svg>
          {Config.canceled}
        </List.Content>
      </List.Item>
    </List>
  </Popup>
);
