import React, { Component } from 'react';
import { Loader, Header } from 'semantic-ui-react';
import { USER_SERVICE } from '../../Services';
import { GoToButton } from '../HelperComponents/GoToButton';
import { CheckAdmin } from './CheckAdmin';
import { UserForm } from './UserForm';

export class UserDetail extends Component {
  render() {
    const { serviceContext, message, history } = this.props;
    const { loading, user } = this.state;
    return (
      <div>
        <CheckAdmin serviceContext={serviceContext} />
        <GoToButton to="/dashboard/users" label="Back to the User List" />
        <Loader active={loading} inline="centered" />
        {!loading && (
          <div>
            <Header as="h2">{user.email}</Header>
            <UserForm userId={user.id} serviceContext={serviceContext} message={message} history={history} />
          </div>
        )}
      </div>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      user: {},
    };

    this.loadUsers(props.match.params.id);
  }

  loadUsers(id) {
    USER_SERVICE.findOne(id)
      .then((user) => {
        this.setState({
          loading: false,
          user,
        });
      })
      .catch((err) => console.error(`failed to fetch user - ${id}`, err));
  }
}
