import React from 'react';
import { Icon, Button, List } from 'semantic-ui-react';
import './InfoWindow.css';

export class PinInfoWindow extends React.Component {
  render() {
    const { data, address, stores, score, cached } = this.props;
    return (
      <div
        className="infowindow-content"
        style={{
          height: !cached && !data ? '150px' : cached ? '100px' : 'inherit',
        }}>
        <h3>{address}</h3>
        {!cached && !data && (
          <div>
            <div className="center" style={{ padding: '15px' }}>
              <Icon name="circle notched" loading size="big" />
            </div>
            <div className="center" style={{ marginTop: '10px' }}>
              Loading Report
            </div>
          </div>
        )}
        {!cached && !!data && (
          <List divided relaxed>
            {score && (
              <List.Item>
                <List.Content>
                  <List.Header>Score</List.Header>
                </List.Content>
                <List.Content floated="right">
                  <List.Description>{score}</List.Description>
                </List.Content>
              </List.Item>
            )}
            {Object.keys(data).map((key, i) => (
              <List.Item key={i}>
                <List.Content>
                  <List.Header>{key}</List.Header>
                </List.Content>
                <List.Content floated="right">
                  <List.Description>{data[key]}</List.Description>
                </List.Content>
              </List.Item>
            ))}
            {!!stores && (
              <List.Item>
                <List.Content>
                  <List.Header>Self Storage Sites</List.Header>
                </List.Content>
                <List.Content floated="right">
                  <List.Description>{stores.length.toString()}</List.Description>
                </List.Content>
              </List.Item>
            )}
          </List>
        )}
        {this.props.storesLoaded && (
          <div className="center">
            <Button onClick={() => this.props.clear()} icon="x" content="Clear" />
          </div>
        )}
      </div>
    );
  }
}

export class StoreInfoWindow extends React.Component {
  render() {
    const { store, details, remove, cached } = this.props;
    return (
      <div>
        <h3>{store.name}</h3>
        {details && (
          <List>
            <List.Item icon="map pin" content={details.formatted_address} />
            {details.website && (
              <List.Item
                icon="linkify"
                content={
                  <a target="_blank" rel="noopener noreferrer" href={details.website}>
                    Website
                  </a>
                }
              />
            )}
          </List>
        )}
        {!cached && (
          <div className="center">
            <Button onClick={() => remove(store)} icon="trash outline" content="Remove" />
          </div>
        )}
      </div>
    );
  }
}
