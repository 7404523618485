import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Message, Icon, Button } from 'semantic-ui-react';
import { STEP_SUBSCRIPTION } from './CreateOrganization';

export class CheckSubscription extends Component {
  render() {
    const { organization } = this.props;

    if (organization.subscription) {
      return null;
    }

    return (
      <div>
        <Message icon warning>
          <Icon name="warning sign" />
          <Message.Content>
            <Message.Header>Subscription is required</Message.Header>
            You may do it later or setup the subscription now.
          </Message.Content>
          <Link
            to={{
              pathname: '/dashboard/organizations/create',
              state: {
                organizationSuccess: true,
                organizationId: organization.id,
                activeStep: STEP_SUBSCRIPTION,
              },
            }}>
            <Button floated="right" primary>
              Setup Subscription
            </Button>
          </Link>
        </Message>
      </div>
    );
  }
}
