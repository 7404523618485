import React from 'react';
import { Popup, Button } from 'semantic-ui-react';
import { Config } from '../../Config';

class Style extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
  }
  render() {
    return (
      <Popup
        trigger={
          <Button
            color={this.state.active ? 'black' : null}
            circular
            icon="map"
            active={this.state.active}
            onClick={() => this.toggleStyle()}
          />
        }
        content="Dark Mode"
      />
    );
  }

  toggleStyle() {
    this.setState({ active: !this.state.active }, () =>
      this.props.map.setOptions({
        styles: this.state.active ? Config.styles : null,
      })
    );
  }
}
export default Style;
