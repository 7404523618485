import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { AUTH_SERVICE } from '../../Services';

class Logout extends Component {
  render() {
    return (
      <div onClick={this.logout.bind(this)} className={this.props.className}>
        {this.props.children}
      </div>
    );
  }

  logout() {
    AUTH_SERVICE.logout()
      .then(() => {
        this.props.history.push('/');
      })
      .catch((err) => console.error('This is unbelievable!', err));
  }
}
export default withRouter(Logout);
