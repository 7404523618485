import React from 'react';
import { Modal, Select, Input, Progress, Icon, Button, Form } from 'semantic-ui-react';

export const DialogDetails = (props) => (
  <Modal dimmer="blurring" open={props.openDialog} size="small">
    <Modal.Header>Batch Analysis</Modal.Header>
    <Modal.Content>
      <Form>
        <Form.Field>
          <label>Offset Distance</label>
          <Select options={props.offSetOptions} defaultValue={props.defaultOffSet} onChange={props.offsetOnChange} />
        </Form.Field>
        <Form.Field>
          <label>Set Square Footage</label>
          <Input
            type="number"
            step="1000"
            label={{ basic: true, content: 'ft²' }}
            labelPosition="right"
            value={props.squareFootageValue}
            onChange={props.squareFootageOnChange}
          />
        </Form.Field>
        {props.started && (
          <Progress color="blue" percent={Math.round(props.progress)} size="small" progress="percent" autoSuccess>
            Time remaining - {props.remainingTime}
          </Progress>
        )}
        {props.isResultOver60 && (
          <Modal.Description>
            <p>
              <Icon name="warning sign" />
              At least <strong>60</strong> storage facilities were found within the map extent. Searching for each point now.
            </p>
          </Modal.Description>
        )}
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button icon="ban" content="Cancel" onClick={props.cancel} />
      {!props.started && <Button positive icon="play" content="Start" disabled={props.started} onClick={props.start} />}
      {props.started && <Button secondary icon="minimize" content="Minimize" onClick={props.minimize} />}
    </Modal.Actions>
  </Modal>
);
