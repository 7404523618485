import React, { Component } from 'react';
import { Modal, Button } from 'semantic-ui-react';

export class Confirm extends Component {
  render() {
    const { open, title, content, onCancel, onConfirm, children } = this.props;

    return (
      <Modal open={open}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content>{children || content}</Modal.Content>
        <Modal.Actions>
          <Button negative content="No" onClick={onCancel} />
          <Button positive labelPosition="right" icon="checkmark" content="Yes" onClick={onConfirm} />
        </Modal.Actions>
      </Modal>
    );
  }
}
