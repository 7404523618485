import React, { Component } from 'react';
import { Form, Button, Input, List, Icon } from 'semantic-ui-react';
import { USER_SERVICE } from '../../Services';
import { Confirm } from '../HelperComponents/Confirm';
import { MessageType } from '../HelperComponents/DismissibleMessage';

export class UserFormRead extends Component {
  render() {
    const { active, user, onToggle } = this.props;
    const { confirmOpen } = this.state;

    if (!active) {
      return null;
    }

    return (
      <div>
        <Form>
          <Form.Field control={Input} label="First Name" readOnly value={user ? user.firstName : ''} />
          <Form.Field control={Input} label="Last Name" readOnly value={user ? user.lastName : ''} />
          <Form.Field control={Input} label="Email" readOnly value={user ? user.email : ''} />
          <Form.Field control={Input} label="Organization" readOnly value={user ? user.organization.name : ''} />
          <Button type="button" icon labelPosition="left" primary onClick={onToggle}>
            <Icon name="edit" />
            Edit
          </Button>
          <Button type="button" floated="right" color="red" icon labelPosition="left" onClick={this.showConfirm.bind(this)}>
            <Icon name="user delete" /> Remove User
          </Button>
        </Form>
        <Confirm
          open={confirmOpen}
          title="Remove User"
          onCancel={this.onConfirmCancel.bind(this)}
          onConfirm={this.onConfirmConfirm.bind(this)}>
          <List>
            <List.Item>
              <List.Header>First Name</List.Header>
              <List.Description>{user.firstName}</List.Description>
            </List.Item>
            <List.Item>
              <List.Header>Last Name</List.Header>
              <List.Description>{user.lastName}</List.Description>
            </List.Item>
            <List.Item>
              <List.Header>Email</List.Header>
              <List.Description>{user.email}</List.Description>
            </List.Item>
            <List.Item>
              <List.Header>Organization</List.Header>
              <List.Description>{user.organization.name}</List.Description>
            </List.Item>
          </List>
        </Confirm>
      </div>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      confirmOpen: false,
    };
  }

  onConfirmCancel() {
    this.dismissConfirm();
  }

  onConfirmConfirm() {
    const { user, history } = this.props;

    USER_SERVICE.remove(user.id)
      .then(() => {
        history.push('/dashboard/users');
        this.showMessage('User was removed successfully.', MessageType.SUCCESS);
      })
      .catch(() => {
        this.showMessage('Failed to remove user', MessageType.ERROR);
      });
  }

  showConfirm() {
    this.setState({ confirmOpen: true });
  }

  dismissConfirm() {
    this.setState({ confirmOpen: false });
  }

  showMessage(message, type) {
    this.props.message({
      [type]: true,
      visible: true,
      content: message,
    });
  }
}
