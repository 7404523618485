import { ApiService } from './ApiService';
import { AuthService } from './AuthService';
import { OrganizationService } from './OrganizationService';
import { SubscriptionService } from './SubscriptionService';
import { UserService } from './UserService';

import { CartoService } from './CartoService';
import { GMapsPlacesServiceWrapper } from './GMapsPlacesServiceWrapper';

export const API_SERVICE = new ApiService();
export const AUTH_SERVICE = new AuthService(API_SERVICE);
export const ORGANIZATION_SERVICE = new OrganizationService(API_SERVICE);
export const SUBSCRIPTION_SERVICE = new SubscriptionService(API_SERVICE);
export const USER_SERVICE = new UserService(API_SERVICE);

export const CARTO_SERVICE = new CartoService();
export const PLACES_SERVICE = new GMapsPlacesServiceWrapper();
