import React, { Component } from 'react';
import { Message, Button } from 'semantic-ui-react';
import { Form, Input } from 'formsy-semantic-ui-react';
import { ORGANIZATION_SERVICE } from '../../Services';
import { ErrorLabel } from '../HelperComponents/ErrorLabel';

export class OrganizationFormCreate extends Component {
  render() {
    const { active } = this.props;
    const { organizationName, isFailed } = this.state;

    if (!active) {
      return null;
    }

    return (
      <Form onValidSubmit={this.onSubmit.bind(this)}>
        <Form.Field required>
          <label>Organization Name</label>
          <Input
            name="organizationName"
            value={organizationName}
            onChange={(e, data) => this.handleChange(e, data)}
            required
            validationErrors={{
              isDefaultRequiredValue: 'Organization name is required',
            }}
            errorLabel={ErrorLabel}
          />
        </Form.Field>
        <Message visible={isFailed} error header="Failed to Create Organization" content="Please try again later." />
        <Button positive>Next</Button>
      </Form>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      ifFailed: false,
      organizationName: '',
      organizationId: null,
    };
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  onSubmit() {
    const { onSubmitSuccess } = this.props;
    const { organizationName } = this.state;
    const organization = { name: organizationName };

    ORGANIZATION_SERVICE.create(organization)
      .then((org) => onSubmitSuccess(org.id))
      .catch((err) => {
        console.error(err);
        this.setState({ failedToCreateOrganization: true });
      });
  }
}
