import { ModelConstants } from '../Helpers/ModelConstants';
import { AbstractDaoService } from './AbstractDaoService';

export class OrganizationService extends AbstractDaoService {
  constructor(apiService) {
    super(
      apiService,
      `/${ModelConstants.ORGANIZATIONS}`,
      'filter[include][subscription]=subscriptionType&filter[include][subscription]=subscriptionLength&filter[include][subscription]=subscriptionStatus'
    );
  }

  findAllSimplified() {
    return this.findAll().then((organizations) => this._simplifyOrganization(organizations));
  }

  findAllPaginatedAndSortedSimplified(page, size, sortBy, sortOrder) {
    return this.findAllPaginatedAndSorted(page, size, sortBy, sortOrder).then((organizations) =>
      this._simplifyOrganization(organizations)
    );
  }

  findAllByFields(fields) {
    const filters = fields.map((x) => `filter[fields][${x}]=true`).join('&');

    return this.findAllByFilters(filters);
  }

  findMatchPaginatedAndSortedSimplified(conditions, page, size, sortBy, sortOrder) {
    return this.findMatchPaginatedAndSorted(conditions, page, size, sortBy, sortOrder).then((organizations) =>
      this._simplifyOrganization(organizations)
    );
  }

  findOne(id) {
    const searchQuery = 'filter[include]=appUsers&filter[include]=usages&filter[include]=subscription';

    return super.findOne(id, searchQuery);
  }

  _simplifyOrganization(organizations) {
    return organizations.map((org) => ({
      id: org.id,
      name: org.name,
      subscriptionType: org.subscription ? org.subscription.subscriptionType.name : null,
      subscriptionStatus: org.subscription ? org.subscription.subscriptionStatus.name : null,
    }));
  }
}
