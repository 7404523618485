import { Config } from '../Config';

/**
 * CARTO SQL API v2 Client
 * <https://carto.com/developers/sql-api/reference/>
 */
export class CartoService {
  constructor() {
    this.baseUrl = Config.dataApiUrl;
  }

  async _sqlWithBackoff(query, resolve, reject, retryCount = 0) {
    let status = 'UNKNOWN_ERROR';

    let delay = 1000;

    try {
      const response = await fetch(`${this.baseUrl}/sql`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          q: query,
        }),
      });

      const body = await response.json();

      if (body.error) {
        status = body.error[0].toUpperCase();
        console.warn(`CARTO SQL API request failed with status: ${status}. Retrying...`);

        const retryAfter = response.headers.get('retry-after');
        if (retryAfter) {
          delay = parseInt(retryAfter) * 1000;
        }
      } else {
        return resolve(body);
      }
    } catch (error) {
      try {
        const body = await error.json();
        console.error(body);
      } catch {
        /* do nothing */
      }

      // reject non-transient errors
      if (error.status) {
        if (status === 400) {
          return reject('BAD_REQUEST');
        }
        if (status === 401) {
          return reject('UNAUTHORIZED');
        }
        if (status === 403) {
          return reject('FORBIDDEN');
        }
      }
    }

    // retry transient errors
    if (retryCount <= 60) {
      setTimeout(() => this._sqlWithBackoff(query, resolve, reject, retryCount + 1), delay);
    } else {
      console.error(`CARTO SQL API request failed after ${retryCount} attempts with status: ${status}.`);
      reject(status);
    }
  }

  /**
   * Query the CARTO SQL API
   */
  async sql(query) {
    return new Promise((resolve, reject) => {
      this._sqlWithBackoff(query, resolve, reject);
    });
  }
}
