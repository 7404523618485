import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { roundup } from '../../../Helpers/Utilities';
import { PinInfoWindow, StoreInfoWindow } from './InfoWindow';
import {} from 'semantic-ui-react';
import { SearchDropDown } from './SearchDropDown';
import './Search.css';

import LogoPublicStorage from './img/public_storage.png';
import LogoUHaul from './img/u-haul.png';
import LogoCubeSmart from './img/cubesmart.png';
import LogoExtraSpace from './img/extra_space.png';
import LogoUncleBobs from './img/uncle_bobs.png';
import LogoCompass from './img/compass.png';
import LogoLifeStorage from './img/life-storage.png';
import LogoSimplyStorage from './img/simply-storage.png';
import LogoSecureSelfStorage from './img/secure-self-storage.png';
import LogoStorageMart from './img/storage-mart.png';
import LogoMetroSelfStorage from './img/metro-self-storage.png';
import LogoStorQuest from './img/storquest.png';
import LogoStorageRentalsOfAmerica from './img/storage-rentals-of-america.png';
import LogoPrimeStorage from './img/prime-storage.png';
import LogoIStorage from './img/istorage.png';
import LogoGreatValueStorage from './img/great-value-storage.png';
import LogoMorningStarStorage from './img/moringstar-storage.png';
import LogoEzStorage from './img/ezstorage.png';
import LogoSafeguardSelfStorage from './img/safeguard-self-storage.png';
import LogoSentrySelfStorage from './img/sentry-self-storage.png';
import LogoUsStorageCenters from './img/us-storage-centers.png';
import LogoAtlanticSelfStorage from './img/atlantic-self-storage.png';
import LogoStoragePost from './img/storage-post.png';
import LogoStorageWest from './img/storage-west.png';
import LogoPlatinumStorage from './img/platinum-storage.png';
import LogoStorage from './img/storage.png';

import Batch from '../Batch/Batch';
import { GetSearchQuery } from '../../../Helpers/Query';
import { Config } from '../../../Config';
import { METER_TO_MILE } from '../../../Helpers/Constants';
import { filterStores } from '../../../Helpers/Filter';
import { CARTO_SERVICE, PLACES_SERVICE } from '../../../Services';

class Search extends Component {
  render() {
    const { gmaps, map, message } = this.props;

    return (
      <div className="search">
        <SearchDropDown
          options={this.options}
          defaultOption={3}
          onInputChange={(event, data) => this.onSearchBoxChange(data.value)}
          onDropDownChange={(event, data) => this.onRadiusChange(data.value)}
          showClearCondition={this.state.searchText !== ''}
          onClearClick={() => this.clear()}
        />
        <Batch gmaps={gmaps} map={map} search={this.search.bind(this)} clear={this.clear.bind(this)} message={message} />
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.options = [
      { value: 1, text: '1 mile' },
      { value: 3, text: '3 miles' },
      { value: 5, text: '5 miles' },
      { value: 10, text: '10 miles' },
    ];
    this.state = {
      radius: this.options[1].value * METER_TO_MILE,
      reportData: undefined,
      searchText: '',
      details: undefined,
      cachedStores: [],
      score: 'Calculating...',
    };
  }

  componentDidUpdate() {
    if (!this.hasEventsBound) {
      this.hasEventsBound = true;
      this.props.map.addListener('click', (e) => this.search(e.latLng));
    }
    if (!this.autocomplete) {
      let input = document.querySelector('#search-box');
      this.autocomplete = new this.props.gmaps.places.Autocomplete(input);
      this.autocomplete.addListener('place_changed', () => {
        const place = this.autocomplete.getPlace().formatted_address || input.value;
        this.setState(
          {
            searchText: place,
          },
          () => this.geocodeAddress(this.state.searchText)
        );
      });
    }
  }

  geocodeAddress(address) {
    if (address === '') {
      return;
    }

    if (!this.geocoder) {
      this.geocoder = new this.props.gmaps.Geocoder();
    }

    this.geocoder.geocode(
      {
        address: address,
      },
      (results, status) => {
        if (status === 'OK') {
          this.point = results[0].geometry.location;
          this.address = results[0].formatted_address;
          this.dropPin();
          this.drawBuffer(true);
        } else {
          alert(`Geocode was not successful for the following reason: ${status}`);
        }
      }
    );
  }

  search(latlng, cached) {
    if (!this.props.clickable) {
      return;
    }

    this.point = latlng;
    this.address = `${latlng.lat()}, ${latlng.lng()}`;
    this.dropPin();
    this.setState({
      cached: !!cached,
      storesLoaded: false,
      searchText: '',
    });
    this.resetSearchBox();
    this.drawBuffer(true, cached);
  }

  dropPin() {
    if (!this.pin) {
      this.pin = new this.props.gmaps.Marker();
      this.pin.addListener('click', () => this.openPopup());
    }
    this.pin.setOptions({
      map: this.props.map,
      position: this.point,
      animation: this.props.gmaps.Animation.DROP,
    });
  }

  openPopup() {
    if (!this.popup) {
      this.popup = new this.props.gmaps.InfoWindow();
    }
    if (!this.pinContent) {
      this.pinContent = document.createElement('div');
    }

    ReactDOM.render(
      <PinInfoWindow
        data={this.state.reportData}
        address={this.address}
        stores={this.state.stores}
        score={this.state.score}
        clear={this.clear.bind(this)}
        cached={this.state.cached}
        storesLoaded={this.state.storesLoaded}
        id="pinInfo"
      />,
      this.pinContent
    );

    this.popup.setOptions({
      content: this.pinContent,
    });
    this.popup.open(this.props.map, this.pin);
  }

  drawBuffer(ZoomTo, cached) {
    if (this.storePopup) {
      this.storePopup.close();
    }

    if (!this.point || !this.address) {
      return;
    }

    if (!this.circle) {
      this.circle = new this.props.gmaps.Circle({
        fillColor: 'red',
        fillOpacity: 0.2,
        strokeColor: 'red',
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });
      this.circle.addListener('click', () => this.openPopup());
    }

    this.circle.setOptions({
      map: this.props.map,
      center: this.point,
      radius: this.state.radius,
    });

    if (ZoomTo) {
      this.ZoomTo();
    }

    this.setState(
      {
        reportData: null,
        stores: [],
        cachedStores: [],
      },
      () => this.query(cached)
    );
  }

  onRadiusChange(value) {
    this.setState({ radius: value * METER_TO_MILE }, () => this.drawBuffer(true));
  }

  ZoomTo() {
    let zoom;
    switch (this.state.radius / METER_TO_MILE) {
      case 1:
        zoom = 14;
        break;
      case 3:
        zoom = 13;
        break;
      case 5:
        zoom = 12;
        break;
      case 10:
        zoom = 11;
        break;
      default:
        zoom = this.props.map.getZoom();
    }
    this.props.map.setZoom(zoom);
    this.props.map.panTo(this.point);
  }

  async query(cached) {
    this.searchStorage(cached);

    this.openPopup();
    const p = this.point;
    const r = this.state.radius;
    const queryString = GetSearchQuery(p.lat(), p.lng(), r);

    try {
      const result = await CARTO_SERVICE.sql(queryString);
      const data = result.rows[0];

      const score = this.calcScore(this.state.stores, data);

      this.setState({
        reportData: data,
        score: score,
      });

      this.openPopup();
    } catch (error) {
      console.error(error);

      this.setState({
        reportData: {},
        score: Config.unqualified,
      });

      this.openPopup();
    }
  }

  searchStorage(cached) {
    this.removeStores();

    if (cached) {
      this.setState(
        {
          stores: cached.stores,
          reportData: cached.info,
        },
        () => {
          this.showStores();
        }
      );
    } else {
      PLACES_SERVICE.nearbySearch({
        location: {
          lat: this.point.lat(),
          lng: this.point.lng(),
        },
        radius: this.state.radius,
        keyword: 'self-storage facilities',
        type: 'storage',
      })
        .then((result) => {
          this.setState({
            cachedStores: this.state.cachedStores.concat(result),
          });

          const stores = filterStores(this.state.cachedStores, this.circle.getCenter(), this.circle.getRadius());

          this.setState(
            {
              stores: stores,
              score: this.state.reportData ? this.calcScore(stores, this.state.reportData) : this.state.score,
            },
            () => {
              this.setState({ storesLoaded: true });
              this.openPopup();
              this.showStores();
            }
          );
        })
        .catch((error) => {
          console.warn(error);
          this.setState({ storesLoaded: true });
        });
    }
  }

  showStores() {
    this.setState({ storesLoaded: true });
    this.state.stores.forEach((store) => {
      const pin = new this.props.gmaps.Marker({
        map: this.props.map,
        position: store.geometry.location,
        animation: this.props.gmaps.Animation.DROP,
        icon: {
          url: this.getBrand(store.name),
        },
      });
      pin.id = store.place_id;
      pin.addListener('click', () => this.showStoreDetails(pin, store));
      this.storePins.push(pin);
    });
  }

  getBrand(name) {
    const brands = [
      { 'Public Storage': LogoPublicStorage },
      { 'U-Haul': LogoUHaul },
      { CubeSmart: LogoCubeSmart },
      { 'Extra Space': LogoExtraSpace },
      { "Uncle Bob's": LogoUncleBobs },
      { Compass: LogoCompass },
      { 'Life Storage': LogoLifeStorage },
      { 'Simply Storage': LogoSimplyStorage },
      { 'Secure Self Storage': LogoSecureSelfStorage },
      { 'Storage Mart': LogoStorageMart },
      { 'Metro Self Storage': LogoMetroSelfStorage },
      { StorQuest: LogoStorQuest },
      { 'Storage Rentels of America': LogoStorageRentalsOfAmerica },
      { 'Prime Storage': LogoPrimeStorage },
      { iStorage: LogoIStorage },
      { 'Great Value Storage': LogoGreatValueStorage },
      { 'Morning Star Storage': LogoMorningStarStorage },
      { ezStorage: LogoEzStorage },
      { 'Safeguard Self Storage': LogoSafeguardSelfStorage },
      { 'Sentry Self Storage': LogoSentrySelfStorage },
      { 'US Storage Centers': LogoUsStorageCenters },
      { 'Atlantic Self Storage': LogoAtlanticSelfStorage },
      { 'Storage Post': LogoStoragePost },
      { 'Storage West': LogoStorageWest },
      { 'Platinum Storage': LogoPlatinumStorage },
    ];
    const filteredBrands = brands.filter((x) => new RegExp(Object.keys(x)[0], 'gi').test(name));
    if (filteredBrands.length === 0) {
      return LogoStorage;
    } else {
      const brand = filteredBrands[0];
      return brand[Object.keys(brand)[0]];
    }
  }

  removeStores() {
    if (this.storePins && this.storePins.length > 0) {
      this.storePins.forEach((pin) => pin.setMap(null));
    }
    this.storePins = [];
    this.setState({ storesLoaded: false });
  }

  showStoreDetails(pin, store) {
    if (!this.storePopup) {
      this.storePopup = new this.props.gmaps.InfoWindow();
    }

    if (!this.storeContent) {
      this.storeContent = document.createElement('div');
    }

    PLACES_SERVICE.getDetails({ placeId: store.place_id }).then((result) => {
      this.setState(
        {
          details: result,
        },
        () => {
          ReactDOM.render(
            <StoreInfoWindow
              store={store}
              details={this.state.details}
              remove={this.removeStore.bind(this)}
              cached={this.state.cached}
            />,
            this.storeContent
          );

          this.storePopup.setOptions({
            content: this.storeContent,
          });
          this.storePopup.open(this.props.map, pin);
        }
      );
    });
  }

  clear() {
    this.setState({
      searchText: '',
    });
    this.resetSearchBox();
    this.removeStores();

    if (this.popup) {
      this.popup.close();
    }
    if (this.popup) {
      this.circle.setMap(null);
    }
    if (this.popup) {
      this.pin.setMap(null);
    }
  }

  resetSearchBox() {
    document.getElementById('search-box').value = '';
  }

  removeStore(store) {
    // TODO - change cached stores to HashMap
    const stores = this.state.stores.filter((x) => x.place_id !== store.place_id);
    this.setState(
      {
        stores: stores,
        score: this.calcScore(stores, this.state.reportData),
      },
      () => {
        this.storePopup.close();
        this.openPopup();
        let index = undefined;
        this.storePins.forEach((x, i) => {
          if (x.id === store.place_id) {
            x.setMap(null);
            index = i;
          }
        });
        if (index) {
          this.storePins.splice(index, 1);
        }
      }
    );
  }

  calcScore(stores, data) {
    const population2020 = +data['2020 Total Population'].replace(/\s|,/g, '');
    return roundup((stores.length * 55000) / population2020, 2);
  }

  onSearchBoxChange(value) {
    this.setState({ searchText: value });
  }
}

export default Search;
