import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Message } from 'semantic-ui-react';
import { Form, Input } from 'formsy-semantic-ui-react';
import { ErrorLabel } from '../HelperComponents/ErrorLabel';
import { LinkButton } from '../HelperComponents/LinkButton';
import { CenteredPanel } from '../HelperComponents/CenteredPanel';
import { AUTH_SERVICE } from '../../Services';

export class Login extends Component {
  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } };
    const { redirectToReferrer, email, password, loginFailed } = this.state;

    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }
    return (
      <CenteredPanel title="Login">
        <Message info visible>
          You must login to view the page at {from.pathname}
        </Message>
        <Form onValidSubmit={this.login.bind(this)}>
          <Form.Field required>
            <label>Email</label>
            <Input
              name="email"
              placeholder="name@example.com"
              autoComplete="username"
              value={email}
              onChange={this.handleChange.bind(this)}
              required
              validations="isEmail"
              validationErrors={{
                isEmail: 'Invalid email',
                isDefaultRequiredValue: 'Email is required',
              }}
              errorLabel={ErrorLabel}
            />
          </Form.Field>
          <Form.Field required>
            <label>Password</label>
            <Input
              type="password"
              autoComplete="current-password"
              name="password"
              value={password}
              onChange={this.handleChange.bind(this)}
              required
              validationErrors={{
                isDefaultRequiredValue: 'Password is required',
              }}
              errorLabel={ErrorLabel}
            />
          </Form.Field>
          <Message
            visible={loginFailed}
            error
            header="Sign in Failed"
            content="Username and password combination is incorrect."
          />
          <Button positive fluid>
            Submit
          </Button>
        </Form>
        <LinkButton to="/request-password-reset" fluid>
          Forgot Password
        </LinkButton>
      </CenteredPanel>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      redirectToReferrer: false,
      email: '',
      password: '',
      loginFailed: false,
    };
  }

  login() {
    const { email, password } = this.state;
    AUTH_SERVICE.login({ email, password })
      .then(() => this.setState({ redirectToReferrer: true }))
      .catch((err) => {
        console.error(err);
        this.setState({ loginFailed: true });
      });
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value, loginFailed: false });
  }
}
