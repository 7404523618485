import React from 'react';
import { List, Button } from 'semantic-ui-react';
import './InfoWindow.css';

class InfoWindow extends React.Component {
  render() {
    const { info, score, stores } = this.props;
    return (
      <div className="infowindow-content">
        <List relaxed divided>
          {score && (
            <List.Item>
              <List.Content>
                <List.Header>Score</List.Header>
              </List.Content>
              <List.Content floated="right">
                <List.Description>{score}</List.Description>
              </List.Content>
            </List.Item>
          )}
          {info &&
            Object.keys(info)
              .filter((key) => !/^raw/.test(key))
              .map((key, i) => (
                <List.Item key={i}>
                  <List.Content>
                    <List.Header>{key}</List.Header>
                  </List.Content>
                  <List.Content floated="right">
                    <List.Description>{info[key]}</List.Description>
                  </List.Content>
                </List.Item>
              ))}
          <List.Item>
            <List.Content>
              <List.Header>Total Facilities Nearby</List.Header>
            </List.Content>
            <List.Content floated="right">
              <List.Description>{stores.length.toString()}</List.Description>
            </List.Content>
          </List.Item>
        </List>
        <div className="center">
          <Button onClick={() => this.search()}>Show Nearby Facilities</Button>
        </div>
      </div>
    );
  }

  search() {
    const { point, search, popup, stores, info } = this.props;
    popup.close();
    search(point, { stores: stores, info: info });
  }
}

export default InfoWindow;
