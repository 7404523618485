import { singleLineString } from './Utilities';
export const GetBatchQuery = (lat, lng, radius) =>
  singleLineString`
  WITH GetProportion AS (
    SELECT
      income_median_2020,
      tenure_total_2020,
      tenure_renter_2020,
      population_2020,
      population_2015,
      population_2010,
      (
        ST_Area(
          ST_Intersection(
            ST_Buffer(
              ST_SetSRID(ST_Point(${lng},${lat}),4326)::geography,
              ${radius}
            ),
            the_geom::geography
          ),
          true
        )
        / ST_Area(the_geom::geography, true)
      )::numeric as proportion

    FROM newman_2020_v4

    WHERE ST_DWithin(
      ST_SetSRID(ST_Point(${lng},${lat}),4326)::geography,
      the_geom::geography,
      ${radius}
    )
  ),

  GetSum AS(
    SELECT
      SUM(population_2020 * proportion)::numeric as sum_population_2020,
      AVG(income_median_2020)::numeric as avg_income_median_2020,
      SUM(tenure_total_2020 * proportion)::numeric as sum_tenure_total_2020,
      SUM(tenure_renter_2020 * proportion)::numeric as sum_tenure_renter_2020,
      SUM(population_2010 * proportion)::numeric as sum_population_2010,
      SUM(population_2015 * proportion)::numeric as sum_population_2015
    FROM GetProportion
    WHERE proportion > 0
  )

  SELECT
    to_char(Round(sum_population_2020), '999,999,999') as "2020 Total Population",
    (Round((sum_population_2020 - sum_population_2015) / sum_population_2015, 2) * 100)::text || '%' as "% Population change 2015 to 2020",
    (Round((sum_population_2020 - sum_population_2010) / sum_population_2010, 2) * 100)::text || '%' as "% Population change 2010 to 2020",
    (Round(sum_tenure_renter_2020 / sum_tenure_total_2020, 2) * 100)::text || '%' as "% 2020 Renter Occupied Housing Units",
    Round(avg_income_median_2020)::money as "2020 Household Income: Median",
    sum_population_2020 as raw_population_2020,
    avg_income_median_2020 as raw_income_median_2020
  FROM GetSum`;

export const GetSearchQuery = (lat, lng, radius) =>
  singleLineString`
  WITH GetProportion AS (
    SELECT
      income_median_2020,
      tenure_total_2020,
      tenure_renter_2020,
      population_2020,
      population_2010,
      population_2015,
      (
        ST_Area(
          ST_Intersection(
            ST_Buffer(
                ST_SetSRID(ST_Point(${lng},${lat}),4326)::geography,
              ${radius}),
            the_geom::geography
          ),
          true
        ) / ST_Area(the_geom::geography, true)
      )::numeric as proportion

    FROM newman_2020_v4

    WHERE ST_DWithin(
      ST_SetSRID(ST_Point(${lng},${lat}),4326)::geography,
      the_geom::geography,
      ${radius})
  ),

  GetSum AS (
    SELECT
      SUM(population_2020 * proportion)::numeric as sum_population_2020,
      AVG(income_median_2020)::numeric as avg_income_median_2020,
      SUM(tenure_total_2020 * proportion)::numeric as sum_tenure_total_2020,
      SUM(tenure_renter_2020 * proportion)::numeric as sum_tenure_renter_2020,
      SUM(population_2010 * proportion)::numeric as sum_population_2010,
      SUM(population_2015 * proportion)::numeric as sum_population_2015
    FROM GetProportion
    WHERE proportion > 0
  )

  SELECT
    to_char(Round(sum_population_2020), '999,999,999') as "2020 Total Population",
    (Round((sum_population_2020 - sum_population_2015) / sum_population_2015, 2) * 100)::text || '%' as "% Population change 2015 to 2020",
    (Round((sum_population_2020 - sum_population_2010) / sum_population_2010, 2) * 100)::text || '%' as "% Population change 2010 to 2020",
    (Round(sum_tenure_renter_2020 / sum_tenure_total_2020, 2) * 100)::text || '%' as "% 2020 Renter Occupied Housing Units",
    Round(avg_income_median_2020)::money as "2020 Household Income: Median"
  FROM GetSum`;
