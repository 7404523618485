import React from 'react';
import { Label, Icon } from 'semantic-ui-react';

export const Chip = (props) => (
  <Label image={!!props.image}>
    {!!props.image && <img src={props.image} alt="icon" />}
    {props.content}
    <Icon name="delete" onClick={props.onClose} />
  </Label>
);
