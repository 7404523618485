import React from 'react';
import { Grid, Segment, Header, Image } from 'semantic-ui-react';
import Logo from '../logo.png';

export const CenteredPanel = (props) => (
  <Grid centered verticalAlign="middle">
    <Grid.Column mobile={16} tablet={8} computer={4}>
      <Segment>
        <Header as="h2" textAlign="center">
          <Header.Content>
            <Image src={Logo} alt="logo" centered />
            {props.title}
          </Header.Content>
        </Header>
        {props.children}
      </Segment>
    </Grid.Column>
  </Grid>
);
