import React from 'react';
import { Button, Popup } from 'semantic-ui-react';
import { Config } from '../../../Config';

export const Eraser = (props) => (
  <Popup
    trigger={<Button onClick={props.clearPoints} icon="eraser" circular color="blue" className="contrast" />}
    content="Clear Points"
    position="bottom center"
    style={Config.popupStyle}
  />
);
