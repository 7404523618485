import React, { Component } from 'react';
import { USER_SERVICE } from '../../Services';
import { UserFormRead } from './UserFormRead';
import { UserFormUpdate } from './UserFormUpdate';

export class UserForm extends Component {
  render() {
    const { serviceContext, message, history } = this.props;
    const { editing, user } = this.state;

    if (!user) {
      return null;
    }

    return (
      <div>
        <UserFormRead
          active={!editing}
          user={user}
          onToggle={this.toggleEdit.bind(this)}
          serviceContext={serviceContext}
          history={history}
          message={message}
        />
        <UserFormUpdate
          active={editing}
          user={user}
          onCancel={this.toggleEdit.bind(this)}
          serviceContext={serviceContext}
          message={message}
          onUpdate={this.updateUser.bind(this)}
        />
      </div>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      user: null,
    };

    this.loadUser();
  }

  toggleEdit() {
    this.setState({ editing: !this.state.editing });
  }

  loadUser() {
    const { userId } = this.props;
    USER_SERVICE.findOne(userId)
      .then((user) => this.setState({ user }))
      .catch((err) => console.error('failed to load user.', err));
  }

  updateUser(user) {
    this.setState({ user, editing: false });
  }
}
