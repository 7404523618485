import React, { Component } from 'react';
import { Menu, Icon } from 'semantic-ui-react';

export class Pagination extends Component {
  render() {
    const { floated, size } = this.props;
    const { page, count, pageSize } = this.state;
    const pages = Math.ceil(count / pageSize);

    return (
      <Menu pagination floated={floated} size={size}>
        <Menu.Item as="a" icon disabled={page === 1} onClick={() => this.prevPage()}>
          <Icon name="left chevron" />
        </Menu.Item>
        {[...Array(pages)].map((x, i) => (
          <Menu.Item as="a" key={i} className={page === i + 1 ? 'active' : null} onClick={() => this.updatePage(i + 1)}>
            {i + 1}
          </Menu.Item>
        ))}
        <Menu.Item as="a" icon disabled={page === pages} onClick={() => this.nextPage()}>
          <Icon name="right chevron" />
        </Menu.Item>
      </Menu>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      page: props.defaultPage || 1,
      count: props.count || 0,
      pageSize: props.pageSize || 5,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      page: nextProps.page,
      count: nextProps.count,
    });
  }

  updatePage(page) {
    const { onChange } = this.props;
    const { pageSize } = this.state;

    this.setState({ page });

    if (onChange) {
      onChange({ page, size: pageSize });
    }
  }

  prevPage() {
    const { page } = this.state;

    if (page === 1) {
      return;
    }

    this.updatePage(this.state.page - 1);
  }

  nextPage() {
    const { page, count, pageSize } = this.state;
    const pages = Math.ceil(count / pageSize);

    if (page === pages) {
      return;
    }

    this.updatePage(this.state.page + 1);
  }
}
