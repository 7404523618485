import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Step, Message, Icon } from 'semantic-ui-react';
import { GoToButton } from '../HelperComponents/GoToButton';
import { CheckAdmin } from './CheckAdmin';
import { SubscriptionFormCreate } from './SubscriptionFormCreate';
import { OrganizationFormCreate } from './OrganizationFormCreate';

export const STEP_ORGANIZATION = 'Organization';
export const STEP_SUBSCRIPTION = 'Subscription';
const STEP_COMPLETE = 'Complete';

export class CreateOrganization extends Component {
  render() {
    const { serviceContext } = this.props;
    const { activeStep, organizationSuccess, subscriptionSuccess, organizationId, redirectToOrganizationList } = this.state;

    if (redirectToOrganizationList) {
      return <Redirect to="/dashboard/organizations" />;
    }

    return (
      <div>
        <CheckAdmin serviceContext={serviceContext} />
        <GoToButton to="/dashboard/organizations" label="Back to the Organization List" />
        <Step.Group>
          <Step
            active={activeStep === STEP_ORGANIZATION}
            completed={organizationSuccess}
            icon="home"
            title="Organization"
            description="Enter organization information"
          />
          <Step
            active={activeStep === STEP_SUBSCRIPTION}
            completed={subscriptionSuccess}
            icon="feed"
            title="Subscription"
            description="Choose subscription options"
          />
          <Step
            active={activeStep === STEP_COMPLETE}
            icon="square outline"
            completed={activeStep === STEP_COMPLETE}
            title="Complete"
          />
        </Step.Group>
        <OrganizationFormCreate
          serviceContext={serviceContext}
          active={activeStep === STEP_ORGANIZATION}
          onSubmitSuccess={this.onOrganizationSubmitSuccess.bind(this)}
        />
        <SubscriptionFormCreate
          organizationId={organizationId}
          serviceContext={serviceContext}
          active={activeStep === STEP_SUBSCRIPTION}
          onSubmitSuccess={this.onSubscriptionSubmitSuccess.bind(this)}
        />
        {activeStep === STEP_COMPLETE && (
          <Message icon>
            <Icon name="circle notched" loading />
            <Message.Content>
              <Message.Header>Organization was successfully created.</Message.Header>
              You are now redirected to the organization list...
            </Message.Content>
          </Message>
        )}
      </div>
    );
  }

  constructor(props) {
    super(props);

    const defaultState = {
      organizationSuccess: false,
      subscriptionSuccess: false,
      activeStep: STEP_ORGANIZATION,
      organizationId: null,
      redirectToOrganizationList: false,
    };

    if (props.location.state) {
      Object.assign(defaultState, props.location.state);
    }

    this.state = defaultState;
  }

  onOrganizationSubmitSuccess(id) {
    this.setState({
      organizationId: id,
      organizationSuccess: true,
      activeStep: STEP_SUBSCRIPTION,
    });
  }

  onSubscriptionSubmitSuccess() {
    this.setState({
      subscriptionSuccess: true,
      activeStep: STEP_COMPLETE,
    });
    setTimeout(() => this.setState({ redirectToOrganizationList: true }), 1500);
  }
}
