import React, { Component } from 'react';
import { Message, Button, Select } from 'semantic-ui-react';
import { Form, Dropdown, Input } from 'formsy-semantic-ui-react';
import { ErrorLabel } from '../HelperComponents/ErrorLabel';
import { USER_SERVICE, ORGANIZATION_SERVICE } from '../../Services';

export class UserFormCreate extends Component {
  render() {
    const { active } = this.props;
    const { firstName, lastName, email, organizations, isFailed } = this.state;

    if (!active) {
      return null;
    }

    return (
      <Form onValidSubmit={this.onSubmit.bind(this)}>
        <Form.Field>
          <label>First Name</label>
          <Input
            name="firstName"
            value={firstName}
            onChange={this.handleChange.bind(this)}
            required
            validationErrors={{
              isDefaultRequiredValue: 'First Name is required',
            }}
            errorLabel={ErrorLabel}
          />
        </Form.Field>
        <Form.Field>
          <label>Last Name</label>
          <Input
            name="lastName"
            value={lastName}
            onChange={this.handleChange.bind(this)}
            required
            validationErrors={{
              isDefaultRequiredValue: 'Last Name is required',
            }}
            errorLabel={ErrorLabel}
          />
        </Form.Field>
        <Form.Field>
          <label>Email</label>
          <Input
            name="email"
            autoComplete="username"
            value={email}
            onChange={this.handleChange.bind(this)}
            required
            validations="isEmail"
            validationErrors={{
              isEmail: 'Invalid email',
              isDefaultRequiredValue: 'Email is required',
            }}
            errorLabel={ErrorLabel}
          />
        </Form.Field>
        <Form.Field>
          <label>Organization</label>
          <Dropdown
            name="organizationId"
            as={Select}
            options={this.convertToSelectOptions(organizations)}
            required
            validationErrors={{
              isDefaultRequiredValue: 'Organization is required',
            }}
            errorLabel={ErrorLabel}
            ref={(c) => {
              this.organizations = c;
            }}
          />
        </Form.Field>
        <Message visible={isFailed} error header="Failed to Create User" content="Please try again later." />
        <Button type="submit" positive>
          Submit
        </Button>
      </Form>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      organizationId: null,
      organizations: [],
      isFailed: false,
    };

    this.loadOrganizations();
  }

  onSubmit(formData) {
    const { organizationId, onSubmitSuccess } = this.props;
    const user = {
      organizationId,
      ...formData,
      password: this.generateRandomPassword(),
    };

    USER_SERVICE.create(user)
      .then(() => onSubmitSuccess())
      .catch((err) => {
        console.error(err);
        this.setState({ isFailed: true });
      });
  }

  loadOrganizations() {
    ORGANIZATION_SERVICE.findAllByFields(['id', 'name'])
      .then((organizations) => this.setState({ organizations }))
      .catch((err) => console.error('failed to load organizations.', err));
  }

  convertToSelectOptions(options) {
    return options.map((o) => ({
      key: o.id,
      value: o.id,
      text: o.name,
    }));
  }

  generateRandomPassword() {
    return Math.random().toString(36).substr(2, 8);
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }
}
