import { ModelConstants } from '../Helpers/ModelConstants';
import { AbstractDaoService } from './AbstractDaoService';

export class SubscriptionService extends AbstractDaoService {
  constructor(apiService) {
    super(
      apiService,
      `/${ModelConstants.SUBSCRIPTIONS}`,
      'filter[include]=subscriptionType&filter[include]=subscriptionLength&filter[include]=subscriptionStatus'
    );
  }

  findOne(id) {
    const searchQuery =
      'filter[include]=subscriptionType&filter[include]=subscriptionLength&filter[include]=subscriptionStatus';

    return super.findOne(id, searchQuery);
  }

  findAllTypes() {
    return this._apiService.doGet(`/${ModelConstants.SUBSCRIPTION_TYPES}`);
  }

  findAllStatuses() {
    return this._apiService.doGet(`/${ModelConstants.SUBSCRIPTION_STATUSES}`);
  }

  findAllLengths() {
    return this._apiService.doGet(`/${ModelConstants.SUBSCRIPTION_LENGTHS}`);
  }
}
