import React from 'react';
import Map from './Map/Map';
import { Dashboard } from './Dashboard/Dashboard';
import { Login } from './UserManagement/Login';
import { PlaceFilter } from './Dashboard/PlaceFilter';
import { Profile } from './Dashboard/Profile';
import { RequestPasswordReset } from './UserManagement/RequestPasswordReset';
import { ResetPassword } from './UserManagement/ResetPassword';
import { OrganizationList } from './UserManagement/OrganizationList';
import { OrganizationDetail } from './UserManagement/OrganizationDetail';
import { CreateOrganization } from './UserManagement/CreateOrganization';
import { UserList } from './UserManagement/UserList';
import { UserDetail } from './UserManagement/UserDetail';
import { CreateUser } from './UserManagement/CreateUser';

export const routes = [
  {
    path: '/',
    name: 'Map',
    component: Map,
    exact: true,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    routes: [
      {
        path: '/dashboard/profile',
        name: 'Profile',
        component: Profile,
      },
      {
        path: '/dashboard/others',
        name: 'Others',
        component: () => <h2>Others</h2>,
      },
      {
        path: '/dashboard/place-filter',
        name: 'Place Filter',
        component: PlaceFilter,
      },
      {
        path: '/dashboard/organizations',
        name: 'Organizations',
        component: OrganizationList,
        exact: true,
      },
      {
        path: '/dashboard/organizations/:id/view',
        name: 'Organization',
        component: OrganizationDetail,
      },
      {
        path: '/dashboard/organizations/create',
        name: 'Organization',
        component: CreateOrganization,
        exact: true,
      },
      {
        path: '/dashboard/users',
        name: 'Users',
        component: UserList,
        exact: true,
      },
      {
        path: '/dashboard/users/:id/view',
        name: 'User',
        component: UserDetail,
      },
      {
        path: '/dashboard/users/create',
        name: 'User',
        component: CreateUser,
        exact: true,
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    public: true,
  },
  {
    path: '/request-password-reset',
    name: 'Request Password Reset',
    component: RequestPasswordReset,
    public: true,
  },
  {
    path: '/reset-password',
    name: 'Request Password Reset',
    component: ResetPassword,
    public: true,
  },
];
