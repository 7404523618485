const Coordinate = window.jsts.geom.Coordinate;
const GeometryFactory = window.jsts.geom.GeometryFactory;
const BufferParameters = window.jsts.operation.buffer.BufferParameters;
/**
 * Vector2
 * @description a data structure that holds a two dimensional point.
 */
export class Vector2 {
  get x() {
    return this._x;
  }
  get y() {
    return this._y;
  }

  constructor(x, y) {
    this._x = x;
    this._y = y;
  }
}

/**
 * vectorCoordinates2Jts
 * @param polygon - an array of vector points
 * @return an array of JSTS coordinates
 */
export const vectorCoordinates2Jts = (polygon) => {
  return polygon.map((vector) => new Coordinate(vector.x, vector.y));
};

/**
 * inflatePolygon
 * @param poly
 * @param spacing
 * @return an array of vector points
 */
export const inflatePolygon = (poly, spacing) => {
  let geoInput = vectorCoordinates2Jts(poly);
  geoInput.push(geoInput[0]);

  let geometryFactory = new GeometryFactory();
  let shell = geometryFactory.createPolygon(geoInput);
  let polygon = shell.buffer(spacing, BufferParameters.CAP_SQUARE);
  let coords = polygon.shell.points.coordinates;
  return coords.map((coord) => new Vector2(coord.x, coord.y));
};
