import React, { Component } from 'react';
import { Popup, Icon, Menu, Button, Progress } from 'semantic-ui-react';
import { Config } from '../../../Config';

export class Wizard extends Component {
  render() {
    return (
      <Popup
        trigger={<Button icon="cubes" circular color="blue" className="contrast" />}
        position="bottom center"
        size="tiny"
        flowing
        hoverable
        open={this.props.minimized || this.state.isOpen}
        onOpen={() => this.handleOpen()}
        onClose={() => this.handleClose()}
        style={Config.popupStyle}>
        <Menu vertical>
          {this.props.minimized && (
            <div>
              <Menu.Item onClick={this.props.onTaskRecoverClick}>
                <Icon name="expand" />
                Current task
              </Menu.Item>
              <Progress attached="bottom" color="blue" percent={this.props.progress} autoSuccess />
            </div>
          )}
          {!this.props.minimized && (
            <div>
              <Menu.Item onClick={this.props.onMapExtentClick}>
                <Icon name="map" />
                Use map extent
              </Menu.Item>
              <Menu.Item onClick={this.props.onDrawingClick}>
                <Icon name="pencil" />
                Draw an area
              </Menu.Item>
            </div>
          )}
        </Menu>
      </Popup>
    );
  }

  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  handleOpen() {
    this.setState({ isOpen: true });
  }

  handleClose() {
    this.setState({ isOpen: false });
  }
}
