let PLACE_FILTER = {
  keywordsBlackList: [],
  keywordsWhiteList: [],
};
export function setPlaceFilter(placeFilter) {
  console.debug(placeFilter);
  PLACE_FILTER = placeFilter;
}

export function filterDuplicateAddress(stores) {
  let cached = {};
  return stores.filter((store) => {
    if (cached[store.vicinity]) {
      return false;
    } else {
      return (cached[store.vicinity] = true);
    }
  });
}

export function filterStores(results, center = null, radius = null) {
  const blockistConditions = PLACE_FILTER.keywordsBlackList.map((x) => '(\\b' + x + '\\b)').join('|');
  const allowListConditions = PLACE_FILTER.keywordsWhiteList.map((x) => '(\\b' + x + '\\b)').join('|');

  let blockListed = true;
  let allowListed = false;

  blockListed = (store) => new RegExp(blockistConditions, 'i').test(store.name);
  allowListed = (store) => new RegExp(allowListConditions, 'i').test(store.name);

  // filter by name
  let stores = results.filter((store) => {
    // reject places without a name
    if (!store?.name) {
      return false;
    }

    // reject generic names
    if (store.name === 'Storage' || store.name === 'Self Storage' || store.name === 'Self-Storage') {
      return false;
    }

    const allowed = allowListed(store);
    if (allowed) {
      console.debug('ALLOW: ' + store.name);
    }

    const blocked = blockListed(store);
    if (blocked && !allowed) {
      console.debug('BLOCK: ' + store.name);
    }

    return allowed || !blocked;
  });

  // filter by distance
  if (window.google.maps.geometry && center && radius) {
    stores = stores.filter((store) => {
      const distanceFromCenter = window.google.maps.geometry.spherical.computeDistanceBetween(store.geometry?.location, center);
      return distanceFromCenter <= radius;
    });
  }

  stores = filterDuplicateAddress(stores);
  return stores;
}
