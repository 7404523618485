import React, { Component } from 'react';
import { Icon, Header, Accordion } from 'semantic-ui-react';
import { UpdatePassword } from '../UserManagement/UpdatePassword';
import { loadDefaultActiveIndex } from '../../Helpers/Utilities';

export class Profile extends Component {
  render() {
    const { serviceContext } = this.props;
    const { defaultActiveIndex } = this.state;
    return (
      <div>
        <Accordion fluid exclusive={false} defaultActiveIndex={defaultActiveIndex}>
          <Accordion.Title>
            <Header>
              <Icon name="dropdown" />
              Change Password
            </Header>
          </Accordion.Title>
          <Accordion.Content>
            <UpdatePassword serviceContext={serviceContext} showMessage={this.showMessage.bind(this)} />
          </Accordion.Content>
        </Accordion>
      </div>
    );
  }

  showMessage(message, type) {
    this.props.message({
      [type]: true,
      visible: true,
      content: message,
    });
  }

  constructor(props) {
    super(props);

    this.state = {
      defaultActiveIndex: loadDefaultActiveIndex(props.location.search, [{ value: 'change-password', index: 0 }]),
    };
  }
}
