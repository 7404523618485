import React, { Component } from 'react';
import { Message, Button, Select } from 'semantic-ui-react';
import { Form, Dropdown } from 'formsy-semantic-ui-react';
import { ErrorLabel } from '../HelperComponents/ErrorLabel';
import { SUBSCRIPTION_SERVICE } from '../../Services';
import { addDays } from '../../Helpers/Utilities';

export class SubscriptionFormCreate extends Component {
  render() {
    const { active } = this.props;
    const { subscriptionTypes, subscriptionStatuses, subscriptionLengths, isFailed } = this.state;

    if (!active) {
      return null;
    }

    return (
      <Form onValidSubmit={this.onSubmit.bind(this)}>
        <Form.Field>
          <label>Subscription Type</label>
          <Dropdown
            name="subscriptionTypeId"
            as={Select}
            options={this.convertToSelectOptions(subscriptionTypes)}
            required
            validationErrors={{
              isDefaultRequiredValue: 'Subscription type is required',
            }}
            errorLabel={ErrorLabel}
          />
        </Form.Field>
        <Form.Field>
          <label>Subscription Statuses</label>
          <Dropdown
            name="subscriptionStatusId"
            as={Select}
            options={this.convertToSelectOptions(subscriptionStatuses)}
            required
            validationErrors={{
              isDefaultRequiredValue: 'Subscription status is required',
            }}
            errorLabel={ErrorLabel}
          />
        </Form.Field>
        <Form.Field>
          <label>Subscription Lengths (Days)</label>
          <Dropdown
            name="subscriptionLengthId"
            as={Select}
            options={this.convertToSelectOptions(subscriptionLengths)}
            required
            validationErrors={{
              isDefaultRequiredValue: 'Subscription length is required',
            }}
            errorLabel={ErrorLabel}
          />
        </Form.Field>
        <Message visible={isFailed} error header="Failed to Create Subscription" content="Please try again later." />
        <Button positive>Submit</Button>
      </Form>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      isFailed: false,
      subscriptionTypes: [],
      subscriptionStatuses: [],
      subscriptionLengths: [],
    };

    this.loadSubscriptionTypes();
    this.loadSubscriptionStatuses();
    this.loadSubscriptionLengths();
  }

  onSubmit(formData) {
    const { subscriptionLengths } = this.state;
    const { organizationId, onSubmitSuccess } = this.props;
    const days = subscriptionLengths.find((x) => x.id === formData.subscriptionLengthId).days;
    const expireDate = this.getExpireDate(days);
    const subscription = {
      organizationId,
      ...formData,
      expireDateTime: expireDate,
    };

    SUBSCRIPTION_SERVICE.create(subscription)
      .then(() => onSubmitSuccess())
      .catch((err) => {
        console.error(err);
        this.setState({ isFailed: true });
      });
  }

  loadSubscriptionTypes() {
    SUBSCRIPTION_SERVICE.findAllTypes()
      .then((subscriptionTypes) => this.setState({ subscriptionTypes }))
      .catch((err) => console.error('failed to load subscription types.', err));
  }

  loadSubscriptionStatuses() {
    SUBSCRIPTION_SERVICE.findAllStatuses()
      .then((subscriptionStatuses) => this.setState({ subscriptionStatuses }))
      .catch((err) => console.error('failed to load subscription statuses.', err));
  }

  loadSubscriptionLengths() {
    SUBSCRIPTION_SERVICE.findAllLengths()
      .then((subscriptionLengths) => this.setState({ subscriptionLengths }))
      .catch((err) => console.error('failed to load subscription lengths.', err));
  }

  convertToSelectOptions(options) {
    return options.map((o) => ({
      key: o.id,
      value: o.id,
      text: o.name || o.days,
    }));
  }

  getExpireDate(days) {
    return addDays(Date.now(), days);
  }
}
