import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Loader } from '@googlemaps/js-api-loader';
import { Menu, Segment, Sidebar, Icon } from 'semantic-ui-react';
import { DismissibleMessage } from '../HelperComponents/DismissibleMessage';
import Search from './Search/Search';
import Style from './Style';
import { Config } from '../../Config';
import Logo from '../logo.png';
import Logout from '../UserManagement/Logout';
import { ModelConstants } from '../../Helpers/ModelConstants';
import { AUTH_SERVICE, API_SERVICE, PLACES_SERVICE } from '../../Services';

import { setPlaceFilter } from '../../Helpers/Filter';

class Map extends Component {
  render() {
    const { serviceContext } = this.props;
    const { gmaps, map, sidebarVisible, messageProps } = this.state;
    return (
      <Sidebar.Pushable as={Segment}>
        <Sidebar as={Menu} animation="overlay" width="thin" direction="right" visible={sidebarVisible} icon="labeled" vertical>
          <Logout serviceContext={serviceContext} className="item link">
            <Menu.Item name="home">
              <Icon name="sign out" /> Log Out
            </Menu.Item>
          </Logout>
          <Link to="/dashboard" className="item">
            <Menu.Item name="setting">
              <Icon name="dashboard" /> Dashboard
            </Menu.Item>
          </Link>
          {AUTH_SERVICE.isAdmin && (
            <Link to="/dashboard/place-filter" className="item">
              <Menu.Item name="setting">
                <Icon name="filter" /> Place Filter
              </Menu.Item>
            </Link>
          )}
        </Sidebar>
        <Sidebar.Pusher className="main-content-pusher">
          <Menu borderless attached="top" className="header-menu">
            <Menu.Item className="main-logo">
              <img src={Logo} alt="Logo" />
            </Menu.Item>
            <Menu.Menu position="right">
              <Menu.Item>
                <Search gmaps={gmaps} map={map} message={this.updateMessage.bind(this)} clickable={!sidebarVisible} />
              </Menu.Item>
              <Menu.Item>
                <Style gmaps={gmaps} map={map} />
              </Menu.Item>
              <Menu.Item name="sidebar" onClick={this.toggleSidebar.bind(this)}>
                <Icon name="bars" />
              </Menu.Item>
            </Menu.Menu>
          </Menu>
          <Segment attached="bottom" className="main-content">
            <div
              ref={(c) => {
                this.map = c;
              }}
              className="map"></div>
            <DismissibleMessage
              {...messageProps}
              updateMessage={this.updateMessage.bind(this)}
              className="dismissible-message"
            />
          </Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      gmaps: {},
      map: {},
      messageProps: {},
      sidebarVisible: false,
    };
  }

  componentDidMount() {
    API_SERVICE.doGet(`/${ModelConstants.PLACE_FILTER}/current`).then((placeFilters) => this.initMap(placeFilters));
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    // when the menu becomes visible, setup some handlers so we can close the menu easily
    if (nextState.sidebarVisible) {
      document.addEventListener('keydown', this.handleKeyPress.bind(this));
      document.querySelector('.pusher').addEventListener('click', this.handleClick.bind(this));
    } else {
      document.removeEventListener('keydown', this.handleKeyPress);
      document.querySelector('.pusher').removeEventListener('click', this.handleClick);
    }
  }

  initMap(placeFilters) {
    setPlaceFilter(placeFilters);

    const loader = new Loader({
      apiKey: Config.gmapsKey,
      libraries: ['places', 'geometry', 'drawing'],
    });

    loader
      .load()
      .then((google) => {
        this.setState({
          gmaps: google.maps,
          map: new google.maps.Map(this.map, {
            center: { lat: 38, lng: -98 },
            zoom: 5,
            scaleControl: true,
            mapTypeId: Config.mapType,
          }),
        });

        PLACES_SERVICE.map = this.state.map;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  updateMessage(messageProps) {
    this.setState({ messageProps });
  }

  toggleSidebar() {
    this.setState({ sidebarVisible: !this.state.sidebarVisible });
  }

  handleClick() {
    if (this.state.sidebarVisible) {
      this.setState({ sidebarVisible: false });
    }
  }

  handleKeyPress(e) {
    if (e.keyCode === 27 && this.state.sidebarVisible) {
      this.setState({ sidebarVisible: false });
    }
  }
}
export default Map;
