import FetchPlease from 'fetch-please';
import { Config } from '../Config';
import { CREDENTIAL } from '../Helpers/Constants';

export class ApiService {
  constructor() {
    const self = this;
    const headers = {
      'Content-Type': 'application/json',
    };
    if (sessionStorage.getItem(CREDENTIAL)) {
      headers.Authorization = JSON.parse(sessionStorage.getItem(CREDENTIAL)).id;
    }
    this.api = new FetchPlease(`${Config.apiUrl}`, {
      headers: headers,
      handleJson(xhr) {
        return xhr.response !== '' ? JSON.parse(xhr.response) : '';
      },
      handleResponse(xhr) {
        const { status } = xhr;

        if (status >= 400) {
          throw self._translateLoopBackError(xhr);
        }
        return xhr;
      },
    });
  }

  setToken(token) {
    this.api.headers = {
      ...this.api.headers,
      Authorization: token,
    };
  }

  doPost(url, data) {
    return this.api.post(url, data);
  }

  doGet(url) {
    return this.api.get(url);
  }

  doPut(url, data) {
    return this.api.put(url, data);
  }

  doDelete(url) {
    return this.api.delete(url);
  }

  _translateLoopBackError(xhr) {
    const { error } = JSON.parse(xhr.response);
    const err = new Error(error.name);
    err.statusCode = error.statusCode;
    err.message = error.message;
    return err;
  }
}
