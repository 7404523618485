import React, { Component } from 'react';
import { Button, Message } from 'semantic-ui-react';
import { Form, Input } from 'formsy-semantic-ui-react';
import { ErrorLabel } from '../HelperComponents/ErrorLabel';
import { MessageType } from '../HelperComponents/DismissibleMessage';
import { AUTH_SERVICE } from '../../Services';

export class UpdatePassword extends Component {
  render() {
    const { currentPassword, newPassword, failedReason } = this.state;
    return (
      <Form ref={(ref) => (this.form = ref)} onValidSubmit={this.updatePassword.bind(this)}>
        <Form.Field required>
          <label>Current Password</label>
          <Input
            name="currentPassword"
            type="password"
            autoComplete="current-password"
            value={currentPassword}
            onChange={this.handleChange.bind(this)}
            required
            validationErrors={{
              isDefaultRequiredValue: 'Current password is required',
            }}
            errorLabel={ErrorLabel}
          />
        </Form.Field>
        <Form.Field required>
          <label>New Password</label>
          <Input
            name="newPassword"
            type="password"
            autoComplete="new-password"
            value={newPassword}
            onChange={this.handleChange.bind(this)}
            required
            validationErrors={{
              isDefaultRequiredValue: 'New password is required',
            }}
            errorLabel={ErrorLabel}
          />
        </Form.Field>
        <Form.Field required>
          <label>Confirm New Password</label>
          <Input
            name="repeatPassword"
            type="password"
            autoComplete="new-password"
            required
            validations="equalsField:newPassword"
            validationErrors={{
              equalsField: 'password must match',
              isDefaultRequiredValue: 'Confirming the new password is required',
            }}
            errorLabel={ErrorLabel}
          />
        </Form.Field>
        <Message visible={!!failedReason} error header="Password Update Failed" content={failedReason} />
        <Button positive>Change Password</Button>
      </Form>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      currentPassword: '',
      newPassword: '',
      repeatPassword: '',
      failedReason: null,
    };
  }

  updatePassword() {
    const { currentPassword, newPassword } = this.state;
    const passwords = { currentPassword, newPassword };
    AUTH_SERVICE.updatePassword(passwords)
      .then(() => this.handleSuccess())
      .catch((err) => this.handleFailed(err));
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  handleFailed(err) {
    this.setState({ failedReason: err.message });
  }

  handleSuccess() {
    this.setState({ failedReason: null });
    this.resetForm();
    this.props.showMessage('Password was updated successfully.', MessageType.SUCCESS);
  }

  resetForm() {
    this.setState(
      {
        currentPassword: '',
        newPassword: '',
        repeatPassword: '',
      },
      () => this.form.reset()
    );
  }
}
