import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class LinkButton extends Component {
  render() {
    const { to, children } = this.props;
    return (
      <Link to={to} style={this.applyStyle()}>
        {children}
      </Link>
    );
  }

  applyStyle() {
    const styles = {};
    const { fluid } = this.props || false;
    if (fluid) {
      Object.assign(styles, {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
      });
    }
    return styles;
  }
}
