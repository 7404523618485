import React from 'react';
import { Select, Input, Button, Icon } from 'semantic-ui-react';

export const SearchDropDown = (props) => (
  <Input placeholder="Search Address, city, zip" icon iconPosition="left" action onChange={props.onInputChange}>
    <Icon name="search" />
    <input id="search-box" style={{ width: '250px' }} />
    {props.showClearCondition && <Button circular icon="x" onClick={props.onClearClick} />}
    <Select
      options={props.options}
      defaultValue={props.defaultOption}
      onChange={props.onDropDownChange}
      style={{ minWidth: '100px' }}
    />
  </Input>
);
