import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { RouteWithSubRoutes } from '../HelperComponents/RouteWithSubRoutes';
import { DismissibleMessage } from '../HelperComponents/DismissibleMessage';
import { Header, Menu, Icon } from 'semantic-ui-react';
import './Dashboard.css';
import Logo from '../logo.png';
import Logout from '../UserManagement/Logout';
import { AUTH_SERVICE } from '../../Services';

const LINK_TO_PROFILE = '/dashboard/profile';
const LINK_TO_OTHERS = '/dashboard/others';
const LINK_TO_PLACE_FILTER = '/dashboard/place-filter';
const LINK_TO_ORGANIZATIONS = '/dashboard/organizations';
const LINK_TO_USERS = '/dashboard/users';

const NaviSidebar = (props) => (
  <Menu vertical pointing secondary>
    <Menu.Item>
      <Menu.Header>General</Menu.Header>
      <Menu.Menu>
        <Link to={LINK_TO_PROFILE} className={props.routeActiveStatus(LINK_TO_PROFILE)}>
          Profile
        </Link>
        <Link to={LINK_TO_OTHERS} className={props.routeActiveStatus(LINK_TO_OTHERS)}>
          Others
        </Link>
      </Menu.Menu>
    </Menu.Item>
    {props.authService.isAdmin && (
      <Menu.Item>
        <Menu.Header>Administration</Menu.Header>
        <Menu.Menu>
          <Link to={LINK_TO_PLACE_FILTER} className={props.routeActiveStatus(LINK_TO_PLACE_FILTER)}>
            Place Filter
          </Link>
          <Link to={LINK_TO_ORGANIZATIONS} className={props.routeActiveStatus(LINK_TO_ORGANIZATIONS)}>
            Organizations
          </Link>
          <Link to={LINK_TO_USERS} className={props.routeActiveStatus(LINK_TO_USERS)}>
            Users
          </Link>
        </Menu.Menu>
      </Menu.Item>
    )}
  </Menu>
);

export class Dashboard extends Component {
  render() {
    const { currentSubRoute, messageProps } = this.state;
    const { name, routes, serviceContext } = this.props;
    return (
      <div>
        <Menu pointing borderless className="header-menu">
          <Menu.Item className="main-logo">
            <img src={Logo} alt="Logo" className="main-logo-img" />
            <Header as="h2">
              Dashboard
              <Header.Subheader>{currentSubRoute ? currentSubRoute.name : name}</Header.Subheader>
            </Header>
          </Menu.Item>
          <Menu.Menu position="right">
            <Link to="/" className="item">
              <Menu.Item>
                <Icon name="map" /> Map
              </Menu.Item>
            </Link>
            <Logout serviceContext={serviceContext} className="item link">
              <Menu.Item>
                <Icon name="sign out" /> Log Out
              </Menu.Item>
            </Logout>
          </Menu.Menu>
        </Menu>
        <div className="two-columns">
          <div className="left-column">
            <NaviSidebar authService={AUTH_SERVICE} routeActiveStatus={this.routeActiveStatus.bind(this)} />
          </div>
          <div className="right-column">
            {routes.map((route, i) => (
              <RouteWithSubRoutes {...route} serviceContext={serviceContext} message={this.updateMessage.bind(this)} key={i} />
            ))}
          </div>
          <DismissibleMessage {...messageProps} updateMessage={this.updateMessage.bind(this)} className="dismissible-message" />
        </div>
      </div>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      currentSubRoute: this.getCurrentSubRoute(props),
      messageProps: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      currentSubRoute: this.getCurrentSubRoute(nextProps),
    });
  }

  getCurrentSubRoute(props) {
    return props.routes.find((route) => new RegExp('^' + route.path, 'i').test(props.location.pathname));
  }

  updateMessage(messageProps) {
    this.setState({ messageProps });
  }

  routeActiveStatus(linkPath) {
    const { currentSubRoute } = this.state;
    return (currentSubRoute && new RegExp('^' + linkPath, 'i').test(currentSubRoute.path) ? ' active' : '') + ' item';
  }
}
