import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Loader, Table, Button, Icon } from 'semantic-ui-react';
import { USER_SERVICE } from '../../Services';
import { CheckAdmin } from './CheckAdmin';
import { SORT_ASC, SORT_DESC, SORT_UI_ASC, SORT_UI_DESC } from '../../Helpers/Constants';
import { Pagination } from '../HelperComponents/Pagination';
import { Filter } from '../HelperComponents/Filter';
import { Config } from '../../Config';
import { toSortOrder } from '../../Helpers/Utilities';

export class UserList extends Component {
  render() {
    const { match, serviceContext } = this.props;
    const { loading, users, sortedColumn, sortOrder, page, size, count } = this.state;
    return (
      <div>
        <CheckAdmin serviceContext={serviceContext} />
        <Loader active={loading} inline="centered" />
        {!loading && (
          <Table celled selectable sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="3">
                  All Users
                  <Filter floated="right" placeholder="Search" onChange={this.onFilterChange.bind(this)} />
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell
                  sorted={sortedColumn === 'firstName' ? sortOrder : null}
                  onClick={() => this.handleSort('firstName')}>
                  First Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sortedColumn === 'lastName' ? sortOrder : null}
                  onClick={() => this.handleSort('lastName')}>
                  Last Name
                </Table.HeaderCell>
                <Table.HeaderCell sorted={sortedColumn === 'email' ? sortOrder : null} onClick={() => this.handleSort('email')}>
                  Email
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {users.map((user, i) => (
                <Table.Row key={i} className="table-row-selected" onClick={() => this.goToUser(user.id)}>
                  <Table.Cell>{user.firstName}</Table.Cell>
                  <Table.Cell>{user.lastName}</Table.Cell>
                  <Table.Cell>{user.email}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell colSpan="3">
                  <Pagination size="small" count={count} page={page} pageSize={size} onChange={this.onPageChange.bind(this)} />
                  <Link to={`${match.path}/create`}>
                    <Button floated="right" icon labelPosition="left" primary>
                      <Icon name="user" /> Add User
                    </Button>
                  </Link>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        )}
      </div>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      users: [],
      sortedColumn: 'id',
      sortOrder: SORT_UI_ASC,
      count: 0,
      page: 1,
      size: Config.defaultPageSize,
      conditions: [],
    };

    this.loadUsers();
  }

  loadUsers() {
    const { sortedColumn, sortOrder, page, size, conditions } = this.state;

    Promise.all([
      USER_SERVICE.findMatchCount(conditions),
      USER_SERVICE.findMatchPaginatedAndSortedSimplified(conditions, page, size, sortedColumn, toSortOrder(sortOrder)),
    ])
      .then((values) => {
        const count = values[0];
        const users = values[1];

        this.setState({
          loading: false,
          users,
          count,
        });
      })
      .catch((err) => console.error('failed to fetch users', err));
  }

  goToUser(id) {
    const { history, match } = this.props;
    history.push(`${match.path}/${id}/view`);
  }

  handleSort(column) {
    const { sortedColumn, sortOrder, size, conditions } = this.state;

    Promise.all([
      USER_SERVICE.findMatchCount(conditions),
      USER_SERVICE.findMatchPaginatedAndSortedSimplified(
        conditions,
        1,
        size,
        column,
        sortedColumn !== column ? SORT_ASC : sortOrder === SORT_UI_ASC ? SORT_DESC : SORT_ASC
      ),
    ])
      .then((values) => {
        const count = values[0];
        const users = values[1];

        this.setState({
          users,
          count,
          page: 1,
          sortedColumn: column,
          sortOrder: sortedColumn !== column ? SORT_UI_ASC : sortOrder === SORT_UI_ASC ? SORT_UI_DESC : SORT_UI_ASC,
        });
      })
      .catch((err) => console.log('failed to fetch users', err));
  }

  onPageChange(data) {
    const { sortedColumn, sortOrder, conditions } = this.state;
    const { page, size } = data;

    Promise.all([
      USER_SERVICE.findMatchCount(conditions),
      USER_SERVICE.findMatchPaginatedAndSortedSimplified(conditions, page, size, sortedColumn, toSortOrder(sortOrder)),
    ])
      .then((values) => {
        const count = values[0];
        const users = values[1];

        this.setState({ users, count, page });
      })
      .catch((err) => console.log('failed to fetch users', err));
  }

  onFilterChange(data) {
    const { sortedColumn, sortOrder, size } = this.state;
    const { value } = data;
    const conditions = [
      {
        property: 'firstName',
        type: 'string',
        value,
      },
      {
        property: 'lastName',
        type: 'string',
        value,
      },
      {
        property: 'email',
        type: 'string',
        value,
      },
    ];

    this.setState({ conditions });

    Promise.all([
      USER_SERVICE.findMatchCount(conditions),
      USER_SERVICE.findMatchPaginatedAndSortedSimplified(conditions, 1, size, sortedColumn, toSortOrder(sortOrder)),
    ])
      .then((values) => {
        const count = values[0];
        const users = values[1];

        this.setState({ users, count, page: 1 });
      })
      .catch((err) => console.log('failed to fetch users', err));
  }
}
