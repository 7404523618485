import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Message } from 'semantic-ui-react';
import { Form, Input } from 'formsy-semantic-ui-react';
import { CenteredPanel } from '../HelperComponents/CenteredPanel';
import { ErrorLabel } from '../HelperComponents/ErrorLabel';
import { AUTH_SERVICE } from '../../Services';

export class ResetPassword extends Component {
  render() {
    const { newPassword, redirectToLogin, requestSent, requestSucceeded, errorMessage } = this.state;
    if (redirectToLogin) {
      return <Redirect to="/login" />;
    }
    return (
      <CenteredPanel title="Request Password Change">
        <Form onValidSubmit={this.submit.bind(this)}>
          <Form.Field required>
            <label>New Password</label>
            <Input
              name="newPassword"
              type="password"
              autoComplete="new-password"
              value={newPassword}
              onChange={this.handleChange.bind(this)}
              required
              validationErrors={{
                isDefaultRequiredValue: 'New password is required',
              }}
              errorLabel={ErrorLabel}
            />
          </Form.Field>
          <Form.Field required>
            <label>Confirm New Password</label>
            <Input
              name="repeatPassword"
              type="password"
              autoComplete="new-password"
              required
              validations="equalsField:newPassword"
              validationErrors={{
                equalsField: 'password must match',
                isDefaultRequiredValue: 'Confirming the new password is required',
              }}
              errorLabel={ErrorLabel}
            />
          </Form.Field>
          <Message
            visible={requestSent && requestSucceeded}
            success
            header="Password Reset Succeeded."
            content="You are not redirected to login..."
          />
          <Message visible={requestSent && !requestSucceeded} error header="Password Reset Failed" content={errorMessage} />
          <Button positive fluid>
            Submit
          </Button>
        </Form>
      </CenteredPanel>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      requestSent: false,
      requestSucceeded: false,
      redirectToLogin: false,
      errorMessage: null,
      newPassword: '',
    };
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value, errorMessage: null });
  }

  submit() {
    const { newPassword } = this.state;
    const { search } = this.props.location;
    const searchMap = this.searchToMap(search);
    const accessTokenId = searchMap.get('access_token');
    const userId = +searchMap.get('id');

    AUTH_SERVICE.resetPassword({ userId, newPassword }, accessTokenId)
      .then(() => this.setState({ requestSent: true, requestSucceeded: true }))
      .then(() => setTimeout(() => this.setState({ redirectToLogin: true }), 2000))
      .catch((err) => {
        this.setState({
          requestSent: true,
          requestSucceeded: false,
          errorMessage: err.message,
        });
        AUTH_SERVICE.removeCredential();
      });
  }

  searchToMap(search) {
    const map = new Map();
    search
      .slice(1)
      .split('&')
      .forEach((str) => {
        const query = str.split('=');
        map.set(query[0], query[1]);
      });
    return map;
  }
}
