import React, { Component } from 'react';
import { Button, Select, List } from 'semantic-ui-react';
import { Form, Dropdown, Input } from 'formsy-semantic-ui-react';
import { USER_SERVICE, ORGANIZATION_SERVICE } from '../../Services';
import { Confirm } from '../HelperComponents/Confirm';
import { MessageType } from '../HelperComponents/DismissibleMessage';
import { ErrorLabel } from '../HelperComponents/ErrorLabel';

export class UserFormUpdate extends Component {
  render() {
    const { active, user } = this.props;
    const { firstName, lastName, organizationId, organizations, confirmOpen } = this.state;

    if (!active) {
      return null;
    }

    return (
      <div>
        <Form ref={(ref) => (this.form = ref)} onValidSubmit={this.onSubmit.bind(this)}>
          <Form.Field>
            <label>First Name</label>
            <Input
              name="firstName"
              value={firstName}
              onChange={this.handleChange.bind(this)}
              required
              validationErrors={{
                isDefaultRequiredValue: 'First Name is required',
              }}
              errorLabel={ErrorLabel}
            />
          </Form.Field>
          <Form.Field>
            <label>Last Name</label>
            <Input
              name="lastName"
              value={lastName}
              onChange={this.handleChange.bind(this)}
              required
              validationErrors={{
                isDefaultRequiredValue: 'Last Name is required',
              }}
              errorLabel={ErrorLabel}
            />
          </Form.Field>
          <Form.Field>
            <label>Email</label>
            <Input name="email" autoComplete="email" value={user.email} readOnly />
          </Form.Field>
          <Form.Field>
            <label>Organization</label>
            <Dropdown
              name="organizationId"
              as={Select}
              options={this.convertToSelectOptions(organizations)}
              defaultValue={organizationId}
              ref={(c) => {
                this.organizations = c;
              }}
            />
          </Form.Field>
          <Button type="button" onClick={this.onCancel.bind(this)}>
            Cancel
          </Button>
          <Button type="submit" positive>
            Save
          </Button>
        </Form>
        <Confirm
          open={confirmOpen}
          title="User Update"
          onCancel={this.onConfirmCancel.bind(this)}
          onConfirm={this.onConfirmConfirm.bind(this)}>
          <List>
            <List.Item>
              <List.Header>First Name</List.Header>
              <List.Description>{firstName}</List.Description>
            </List.Item>
            <List.Item>
              <List.Header>Last Name</List.Header>
              <List.Description>{lastName}</List.Description>
            </List.Item>
            <List.Item>
              <List.Header>Organization</List.Header>
              <List.Description>{organizations.find((x) => x.id === organizationId).name}</List.Description>
            </List.Item>
          </List>
        </Confirm>
      </div>
    );
  }

  constructor(props) {
    super(props);

    this.defaultState = {
      firstName: props.user.firstName,
      lastName: props.user.lastName,
      organizationId: props.user.organizationId,
      confirmOpen: false,
    };

    this.state = {
      ...this.defaultState,
      organizations: [],
    };

    this.loadOrganizations();
  }

  loadOrganizations() {
    ORGANIZATION_SERVICE.findAllByFields(['id', 'name'])
      .then((organizations) => this.setState({ organizations }))
      .catch((err) => console.error('failed to load organizations.', err));
  }

  convertToSelectOptions(options) {
    return options.map((o) => ({
      key: o.id,
      value: o.id,
      text: o.name,
    }));
  }

  onCancel() {
    this.form.reset();
    this.revertToDefault();
    this.props.onCancel();
  }

  onSubmit() {
    this.setState({ organizationId: this.organizations.getValue() }, () => this.showConfirm());
  }

  onConfirmCancel() {
    this.dismissConfirm();
  }

  onConfirmConfirm() {
    const { user } = this.props;
    const { firstName, lastName, organizationId } = this.state;
    const newUser = {
      ...user,
      firstName,
      lastName,
      organizationId,
    };
    delete newUser.organization;

    USER_SERVICE.update(user.id, newUser)
      .then(() => {
        this.dismissConfirm();
        this.showMessage('Successfully updated', MessageType.SUCCESS);
        this.updateDefault();
        this.revertToDefault();
        this.updateUser(newUser);
      })
      .catch(() => this.showMessage('Failed to update', MessageType.ERROR));
  }

  showConfirm() {
    this.setState({ confirmOpen: true });
  }

  dismissConfirm() {
    this.setState({ confirmOpen: false });
  }

  showMessage(message, type) {
    this.props.message({
      [type]: true,
      visible: true,
      content: message,
    });
  }

  updateDefault() {
    const { firstName, lastName, organizationId } = this.state;

    this.defaultState = {
      ...this.defaultState,
      firstName,
      lastName,
      organizationId,
    };
  }

  revertToDefault() {
    this.setState({
      ...this.state,
      ...this.defaultState,
    });
  }

  updateUser(newUser) {
    const { organizations } = this.state;
    Object.assign(newUser, {
      organization: organizations.find((x) => x.id === newUser.organizationId),
    });
    this.props.onUpdate(newUser);
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }
}
