import React, { Component } from 'react';
import { Card, Message } from 'semantic-ui-react';

export class OrganizationUserList extends Component {
  render() {
    const { organization } = this.props;
    const { isEmpty } = this.state;

    if (isEmpty) {
      return <Message info>There is no user belonging to this organization.</Message>;
    }

    return (
      <Card.Group>
        {organization.appUsers.map((user, i) => (
          <Card key={i} link onClick={() => this.goToUser(user.id)}>
            <Card.Content>
              <Card.Header>
                {user.firstName} {user.lastName}
              </Card.Header>
              <Card.Meta>{organization.name}</Card.Meta>
              <Card.Description>{user.email}</Card.Description>
            </Card.Content>
          </Card>
        ))}
      </Card.Group>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      isEmpty: !props.organization.appUsers || props.organization.appUsers.length === 0,
    };
  }

  goToUser(id) {
    const { history } = this.props;

    history.push(`/dashboard/users/${id}/view`);
  }
}
