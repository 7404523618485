import React, { Component } from 'react';
import { Loader, Header, Accordion, Icon } from 'semantic-ui-react';
import { ORGANIZATION_SERVICE } from '../../Services';
import { Subscription } from './Subscription';
import { GoToButton } from '../HelperComponents/GoToButton';
import { CheckAdmin } from './CheckAdmin';
import { CheckSubscription } from './CheckSubscription';
import { loadDefaultActiveIndex } from '../../Helpers/Utilities';
import { OrganizationUserList } from './OrganizationUserList';

export class OrganizationDetail extends Component {
  render() {
    const { serviceContext, message, history } = this.props;
    const { loading, organization, defaultActiveIndex } = this.state;
    return (
      <div>
        <CheckAdmin serviceContext={serviceContext} />
        <GoToButton to="/dashboard/organizations" label="Back to the Organization List" />
        <Loader active={loading} inline="centered" />
        {!loading && (
          <div>
            <Header as="h2">{organization.name}</Header>
            <Accordion fluid exclusive={false} defaultActiveIndex={defaultActiveIndex}>
              <Accordion.Title>
                <Header>
                  <Icon name="dropdown" />
                  Subscription
                </Header>
              </Accordion.Title>
              <Accordion.Content>
                <CheckSubscription organization={organization} />
                {organization.subscription && (
                  <Subscription
                    subscriptionId={organization.subscription.id}
                    serviceContext={serviceContext}
                    message={message}
                  />
                )}
              </Accordion.Content>
              <Accordion.Title>
                <Header>
                  <Icon name="dropdown" />
                  Users
                </Header>
              </Accordion.Title>
              <Accordion.Content>
                <OrganizationUserList organization={organization} history={history} />.
              </Accordion.Content>
            </Accordion>
          </div>
        )}
      </div>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      organization: {},
      defaultActiveIndex: loadDefaultActiveIndex(props.location.search, [
        { value: 'subscription', index: 0 },
        { value: 'users', index: 1 },
      ]),
    };

    this.loadOrganization(props.match.params.id);
  }

  loadOrganization(id) {
    ORGANIZATION_SERVICE.findOne(id)
      .then((organization) => {
        this.setState({
          loading: false,
          organization,
        });
      })
      .catch((err) => console.error(`failed to fetch organization - ${id}`, err));
  }
}
