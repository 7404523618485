import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Step, Message, Icon } from 'semantic-ui-react';
import { GoToButton } from '../HelperComponents/GoToButton';
import { CheckAdmin } from './CheckAdmin';
import { UserFormCreate } from './UserFormCreate';

export const STEP_USER = 'Subscription';
const STEP_COMPLETE = 'Complete';

export class CreateUser extends Component {
  render() {
    const { serviceContext } = this.props;
    const { activeStep, userSuccess, redirectToUserList } = this.state;

    if (redirectToUserList) {
      return <Redirect to="/dashboard/users" />;
    }

    return (
      <div>
        <CheckAdmin serviceContext={serviceContext} />
        <GoToButton to="/dashboard/users" label="Back to the user List" />
        <Step.Group>
          <Step
            active={activeStep === STEP_USER}
            completed={userSuccess}
            icon="user"
            title="User"
            description="Enter user information"
          />
          <Step
            active={activeStep === STEP_COMPLETE}
            icon="square outline"
            completed={activeStep === STEP_COMPLETE}
            title="Complete"
          />
        </Step.Group>
        <UserFormCreate
          serviceContext={serviceContext}
          active={activeStep === STEP_USER}
          onSubmitSuccess={this.onUserSubmitSuccess.bind(this)}
        />
        {activeStep === STEP_COMPLETE && (
          <Message icon>
            <Icon name="circle notched" loading />
            <Message.Content>
              <Message.Header>User was successfully created.</Message.Header>
              You are now redirected to the user list...
            </Message.Content>
          </Message>
        )}
      </div>
    );
  }

  constructor(props) {
    super(props);

    const defaultState = {
      userSuccess: false,
      activeStep: STEP_USER,
      redirectToUserList: false,
    };

    if (props.location.state) {
      Object.assign(defaultState, props.location.state);
    }

    this.state = defaultState;
  }

  onUserSubmitSuccess() {
    this.setState({
      userSuccess: true,
      activeStep: STEP_COMPLETE,
    });
    setTimeout(() => this.setState({ redirectToUserList: true }), 1500);
  }
}
