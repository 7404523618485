import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

export const GoToButton = (props) => (
  <Link to={props.to}>
    <Button basic floated="right">
      {props.label}
    </Button>
  </Link>
);
