/**
 * Wraps Google Maps Places Service
 * to retry failed requests with exponential backoff.
 */
export class GMapsPlacesServiceWrapper {
  set map(value) {
    this.places = new window.google.maps.places.PlacesService(value);
  }

  _getDetailsWithBackoff(opts, resolve, reject, retryCount = 0) {
    this.places.getDetails(opts, (result, status) => {
      if (status === 'OK') {
        return resolve(result);
      }

      if (status === 'ZERO_RESULTS') {
        return resolve(null, null);
      }

      // non-transient errors
      if (status === 'NOT_FOUND' || status === 'INVALID_REQUEST' || status === 'REQUEST_DENIED') {
        return reject(status);
      }

      // retry transient errors
      if (retryCount <= 12) {
        console.warn(`Places API request failed with status: ${status}. Retrying...`);
        const delay = 10 + 2 ** retryCount; // exponential backoff
        setTimeout(() => this._getDetailsWithBackoff(opts, resolve, reject, retryCount + 1), delay);
      } else {
        console.error(`Places API request failed after ${retryCount} attempts with status: ${status}.`);
        reject(status);
      }
    });
  }

  /**
   * Nearby search
   */
  async getDetails(opts) {
    return new Promise((resolve, reject) => {
      this._getDetailsWithBackoff(opts, resolve, reject);
    });
  }

  _nearbySearchWithBackoff(opts, resolve, reject, retryCount = 0) {
    let results;

    this.places.nearbySearch(opts, (result, status, pagination = null) => {
      if (status === 'OK') {
        if (!results) {
          results = result;
        } else {
          results = results.concat(result);
        }

        if (pagination?.hasNextPage) {
          pagination.nextPage();
          return;
        } else {
          return resolve(results);
        }
      }

      if (status === 'ZERO_RESULTS') {
        return resolve(null);
      }

      // non-transient errors
      if (status === 'NOT_FOUND' || status === 'INVALID_REQUEST' || status === 'REQUEST_DENIED') {
        return reject(status);
      }

      // retry transient errors
      if (retryCount <= 12) {
        console.warn(`Places API request failed with status: ${status}. Retrying...`);
        const delay = 10 + 2 ** retryCount; // exponential backoff
        setTimeout(() => this._nearbySearchWithBackoff(opts, resolve, reject, retryCount + 1), delay);
      } else {
        console.error(`Places API request failed after ${retryCount} attempts with status: ${status}.`);
        reject(status);
      }
    });
  }

  /**
   * Nearby search
   */
  async nearbySearch(opts) {
    return new Promise((resolve, reject) => {
      this._nearbySearchWithBackoff(opts, resolve, reject);
    });
  }
}
