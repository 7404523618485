import { toWhereClause } from '../Helpers/Utilities';

export class AbstractDaoService {
  constructor(apiService, base, includeAll) {
    this._apiService = apiService;
    this._base = base;
    this._includeAll = includeAll;

    if (this._apiService === undefined) {
      throw new TypeError('Must override property _apiService');
    }

    if (this._base === undefined) {
      throw new TypeError('Must override property _base');
    }

    if (this._includeAll === undefined) {
      throw new TypeError('Must override property _includeAll');
    }
  }

  findAll() {
    const searchQuery = `${this._includeAll}`;

    return this._apiService.doGet(`${this._base}?${searchQuery}`);
  }

  findAllPaginatedAndSorted(page, size, sortBy, sortOrder) {
    const skipSize = (page - 1) * size;
    const searchQuery = `${this._includeAll}&filter[limit]=${size}&filter[skip]=${skipSize}&filter[order]=${sortBy} ${sortOrder}`;

    return this._apiService.doGet(`${this._base}?${searchQuery}`);
  }

  findAllByFilters(filters) {
    return this._apiService.doGet(`${this._base}?${filters}`);
  }

  findMatchPaginatedAndSorted(conditions, page, size, sortBy, sortOrder) {
    const where = toWhereClause(conditions);
    const skipSize = (page - 1) * size;
    const searchQuery = `${where}&${this._includeAll}&filter[limit]=${size}&filter[skip]=${skipSize}&filter[order]=${sortBy} ${sortOrder}`;

    return this._apiService.doGet(`${this._base}?${searchQuery}`);
  }

  findOne(id, searchQuery = '') {
    return this._apiService.doGet(`${this._base}/${id}?${searchQuery}`);
  }

  findAllCount() {
    return this._apiService.doGet(`${this._base}/count`);
  }

  findMatchCount(conditions) {
    const where = toWhereClause(conditions);
    return this._apiService.doGet(`${this._base}?${where}&filter[fields][id]=true`).then((collection) => collection.length);
  }

  create(resource) {
    return this._apiService.doPost(`${this._base}`, resource);
  }

  update(id, resource) {
    return this._apiService.doPut(`${this._base}/${id}`, resource);
  }

  remove(id) {
    return this._apiService.doDelete(`${this._base}/${id}`);
  }
}
