import React, { Component } from 'react';
import { Form, Button, Input, Icon } from 'semantic-ui-react';
import { toLocalDate } from '../../Helpers/Utilities';

export class SubscriptionFormRead extends Component {
  render() {
    const { active, subscription, onToggle } = this.props;

    if (!active) {
      return null;
    }

    return (
      <div>
        <Form>
          <Form.Field
            control={Input}
            label="Subscription Type"
            readOnly
            value={subscription ? subscription.subscriptionType.name : ''}
          />
          <Form.Field
            control={Input}
            label="Subscription Status"
            readOnly
            value={subscription ? subscription.subscriptionStatus.name : ''}
          />
          <Form.Field
            control={Input}
            label="Subscription Length (Days)"
            readOnly
            value={subscription ? subscription.subscriptionLength.days : ''}
          />
          <Form.Field>
            <label>Expires on</label>
            <Input
              readOnly
              value={subscription ? toLocalDate(subscription.expireDateTime) : ''}
              label={{
                color: this.isExpired ? 'red' : 'teal',
                content: this.isExpired ? 'Subscription expired on' : `Expires in ${this.expireDays} Days`,
              }}
              labelPosition="left"
            />
          </Form.Field>
          <Button type="button" icon labelPosition="left" primary onClick={onToggle}>
            <Icon name="edit" />
            Edit
          </Button>
        </Form>
      </div>
    );
  }

  get expireDays() {
    const { expireDateTime } = this.props.subscription;
    const currentExpireDate = new Date(expireDateTime);
    const today = Date.now();
    return Math.ceil((currentExpireDate - today) / (24 * 60 * 60 * 1000));
  }

  get isExpired() {
    return this.expireDays < 0;
  }
}
