import React, { Component } from 'react';
import { Button, Select, Input, List } from 'semantic-ui-react';
import { Form, Dropdown } from 'formsy-semantic-ui-react';
import { SUBSCRIPTION_SERVICE } from '../../Services';
import { toLocalDate, addDays } from '../../Helpers/Utilities';
import { Confirm } from '../HelperComponents/Confirm';
import { MessageType } from '../HelperComponents/DismissibleMessage';

export class SubscriptionFormUpdate extends Component {
  render() {
    const { active, subscription } = this.props;
    const {
      subscriptionTypes,
      subscriptionStatuses,
      subscriptionLengths,
      newExpireDate,
      newSubscription,
      renewed,
      confirmOpen,
    } = this.state;

    if (!active) {
      return null;
    }

    return (
      <div>
        <Form
          ref={(ref) => {
            this.form = ref;
          }}
          onValidSubmit={this.onSubmit.bind(this)}>
          <Form.Field>
            <label>Subscription Type</label>
            <Dropdown
              name="subscriptionTypeId"
              as={Select}
              options={this.convertToSelectOptions(subscriptionTypes)}
              defaultValue={subscription && subscription.subscriptionTypeId}
            />
          </Form.Field>
          <Form.Field>
            <label>Subscription Status</label>
            <Dropdown
              name="subscriptionStatusId"
              as={Select}
              options={this.convertToSelectOptions(subscriptionStatuses)}
              defaultValue={subscription && subscription.subscriptionStatusId}
            />
          </Form.Field>
          <Form.Field>
            <label>Subscription Length (Days)</label>
            <Dropdown
              name="subscriptionLengthId"
              as={Select}
              options={this.convertToSelectOptions(subscriptionLengths)}
              defaultValue={subscription && subscription.subscriptionLengthId}
              ref={(c) => {
                this.subscriptionLength = c;
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Expires on</label>
            <Input
              name="expireDateTime"
              value={subscription ? toLocalDate(newExpireDate) : ''}
              readOnly
              label={{
                color: this.isExpired ? 'red' : 'teal',
                content: this.isExpired ? 'Subscription expired on' : `Expires in ${this.expireDays} Days`,
              }}
              labelPosition="left"
              action={
                renewed
                  ? {
                      color: 'blue',
                      labelPosition: 'right',
                      icon: 'undo',
                      content: 'Revert',
                      type: 'button',
                      onClick: () => this.onRevertClick(),
                    }
                  : {
                      color: 'blue',
                      labelPosition: 'right',
                      icon: 'refresh',
                      content: 'Renew Now',
                      type: 'button',
                      onClick: () => this.onRenewClick(),
                    }
              }
            />
          </Form.Field>
          <Button type="button" onClick={this.onCancel.bind(this)}>
            Cancel
          </Button>
          <Button type="submit" positive>
            Save
          </Button>
        </Form>
        <Confirm
          open={confirmOpen}
          title="Subscription Update"
          onCancel={this.onConfirmCancel.bind(this)}
          onConfirm={this.onConfirmConfirm.bind(this)}>
          <List>
            <List.Item>
              <List.Header>Type</List.Header>
              <List.Description>
                {subscriptionTypes.find((x) => x.id === newSubscription.subscriptionTypeId).name}
              </List.Description>
            </List.Item>
            <List.Item>
              <List.Header>Status</List.Header>
              <List.Description>
                {subscriptionStatuses.find((x) => x.id === newSubscription.subscriptionStatusId).name}
              </List.Description>
            </List.Item>
            <List.Item>
              <List.Header>Length (Days)</List.Header>
              <List.Description>
                {subscriptionLengths.find((x) => x.id === newSubscription.subscriptionLengthId).days}
              </List.Description>
            </List.Item>
            <List.Item>
              <List.Header>Expires on</List.Header>
              <List.Description>{toLocalDate(newSubscription.expireDateTime)}</List.Description>
            </List.Item>
          </List>
        </Confirm>
      </div>
    );
  }

  get expireDays() {
    const currentExpireDate = new Date(this.state.newExpireDate);
    const today = Date.now();
    return Math.ceil((currentExpireDate - today) / (24 * 60 * 60 * 1000));
  }

  get isExpired() {
    return this.expireDays < 0;
  }

  constructor(props) {
    super(props);

    this.defaultState = {
      newExpireDate: new Date(props.subscription.expireDateTime),
      subscriptionLengthId: props.subscription.subscriptionLengthId,
      renewed: false,
      confirmOpen: false,
      newSubscription: props.subscription,
    };

    this.state = {
      ...this.defaultState,
      subscriptionTypes: [],
      subscriptionStatuses: [],
      subscriptionLengths: [],
    };

    this.loadSubscriptionTypes();
    this.loadSubscriptionStatuses();
    this.loadSubscriptionLengths();
  }

  loadSubscriptionTypes() {
    SUBSCRIPTION_SERVICE.findAllTypes()
      .then((subscriptionTypes) => this.setState({ subscriptionTypes }))
      .catch((err) => console.error('failed to load subscription types.', err));
  }

  loadSubscriptionStatuses() {
    SUBSCRIPTION_SERVICE.findAllStatuses()
      .then((subscriptionStatuses) => this.setState({ subscriptionStatuses }))
      .catch((err) => console.error('failed to load subscription statuses.', err));
  }

  loadSubscriptionLengths() {
    SUBSCRIPTION_SERVICE.findAllLengths()
      .then((subscriptionLengths) => this.setState({ subscriptionLengths }))
      .catch((err) => console.error('failed to load subscription lengths.', err));
  }

  convertToSelectOptions(options) {
    return options.map((o) => ({
      key: o.id,
      value: o.id,
      text: o.name || o.days,
    }));
  }

  onCancel() {
    this.form.reset();
    this.setState({ ...this.defaultState });
    this.props.onCancel();
  }

  onSubmit(formData) {
    const { id, organizationId } = this.props.subscription;
    const { subscriptionLengths, renewed } = this.state;

    const newDays = subscriptionLengths.find((x) => x.id === formData.subscriptionLengthId).days;
    const subscription = { ...formData, id, organizationId };

    if (renewed) {
      subscription.expireDateTime = this.state.newExpireDate;
      this.showConfirm();
    } else {
      const newExpireDate = this.updateExpireDate(newDays);

      subscription.expireDateTime = newExpireDate;
      this.setState({ newExpireDate });
    }
    this.setState({ newSubscription: subscription }, () => this.showConfirm());
  }

  onRenewClick() {
    const { subscriptionLengths } = this.state;
    const newDays = subscriptionLengths.find((x) => x.id === this.subscriptionLength.getValue()).days;
    const newExpireDate = this.renewExpireDate(newDays);
    this.setState({
      newExpireDate,
      renewed: true,
    });
  }

  onRevertClick() {
    const { subscription } = this.props;
    this.setState({
      newExpireDate: new Date(subscription.expireDateTime),
      renewed: false,
    });
  }

  onConfirmCancel() {
    this.dismissConfirm();
  }

  onConfirmConfirm() {
    const { newSubscription } = this.state;
    SUBSCRIPTION_SERVICE.update(newSubscription.id, newSubscription)
      .then((subscription) => {
        this.dismissConfirm();
        this.showMessage('Successfully updated', MessageType.SUCCESS);
        this.updateDefault();
        this.setState({
          ...this.state,
          ...this.defaultState,
        });
        this.updateSubscription(subscription);
      })
      .catch(() => this.showMessage('Failed to update', MessageType.ERROR));
  }

  showConfirm() {
    this.setState({ confirmOpen: true });
  }

  dismissConfirm() {
    this.setState({ confirmOpen: false });
  }

  showMessage(message, type) {
    this.props.message({
      [type]: true,
      visible: true,
      content: message,
    });
  }

  updateDefault() {
    this.defaultState = {
      ...this.defaultState,
      newExpireDate: this.state.newExpireDate,
      newSubscription: this.state.newSubscription,
    };
  }

  updateSubscription(newSubscription) {
    const { subscriptionTypes, subscriptionStatuses, subscriptionLengths } = this.state;
    Object.assign(newSubscription, {
      subscriptionType: subscriptionTypes.find((x) => x.id === newSubscription.subscriptionTypeId),
      subscriptionStatus: subscriptionStatuses.find((x) => x.id === newSubscription.subscriptionStatusId),
      subscriptionLength: subscriptionLengths.find((x) => x.id === newSubscription.subscriptionLengthId),
    });
    this.props.onUpdate(newSubscription);
  }

  /**
   * only used to change the subscription setting - not to renew subscription.
   * @param newDays
   */
  updateExpireDate(newDays) {
    const { expireDateTime, subscriptionLength } = this.props.subscription;
    const currentExpireDate = new Date(expireDateTime);
    const currentDays = subscriptionLength.days;

    if (currentDays === newDays) {
      return currentExpireDate;
    }

    return addDays(currentExpireDate, newDays - currentDays);
  }
  /**
   * only used to renew subscription ExpireDate.
   * @param days
   */
  renewExpireDate(newDays) {
    const { expireDateTime } = this.props.subscription;
    const currentExpireDate = new Date(expireDateTime);
    const today = Date.now();

    if (currentExpireDate < today) {
      return addDays(today, newDays);
    }

    return addDays(currentExpireDate, newDays);
  }
}
