import React, { Component } from 'react';
import { SUBSCRIPTION_SERVICE } from '../../Services';
import { SubscriptionFormRead } from './SubscriptionFormRead';
import { SubscriptionFormUpdate } from './SubscriptionFormUpdate';

export class Subscription extends Component {
  render() {
    const { serviceContext, message } = this.props;
    const { editing, subscription } = this.state;

    if (!subscription) {
      return null;
    }

    return (
      <div>
        <SubscriptionFormRead active={!editing} subscription={subscription} onToggle={this.toggleEdit.bind(this)} />
        <SubscriptionFormUpdate
          active={editing}
          subscription={subscription}
          onCancel={this.toggleEdit.bind(this)}
          serviceContext={serviceContext}
          message={message}
          onUpdate={this.updateSubscription.bind(this)}
        />
      </div>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      subscription: null,
    };

    this.loadSubscription();
  }

  toggleEdit() {
    this.setState({ editing: !this.state.editing });
  }

  loadSubscription() {
    const { subscriptionId } = this.props;
    SUBSCRIPTION_SERVICE.findOne(subscriptionId)
      .then((subscription) => this.setState({ subscription }))
      .catch((err) => console.error('failed to load subscription.', err));
  }

  updateSubscription(subscription) {
    this.setState({ subscription, editing: false });
  }
}
