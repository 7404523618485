import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Message } from 'semantic-ui-react';
import { Form, Input } from 'formsy-semantic-ui-react';
import { CenteredPanel } from '../HelperComponents/CenteredPanel';
import { ErrorLabel } from '../HelperComponents/ErrorLabel';
import { LinkButton } from '../HelperComponents/LinkButton';
import { AUTH_SERVICE } from '../../Services';

export class RequestPasswordReset extends Component {
  render() {
    const { email, redirectToLogin, requestSent, requestSucceeded, errorMessage } = this.state;
    if (redirectToLogin) {
      return <Redirect to="/login" />;
    }
    return (
      <CenteredPanel title="Request Password Change">
        <Form onValidSubmit={this.sendRequest.bind(this)}>
          <Form.Field required>
            <label>Email associated with this account</label>
            <Input
              name="email"
              autoComplete="username"
              placeholder="name@example.com"
              value={email}
              onChange={this.handleChange.bind(this)}
              required
              validations="isEmail"
              validationErrors={{
                isEmail: 'Invalid email',
                isDefaultRequiredValue: 'Email is required',
              }}
              errorLabel={ErrorLabel}
            />
          </Form.Field>
          <Message
            visible={requestSent && requestSucceeded}
            success
            header="Request Sent"
            content="Please check the inbox for next step."
          />
          <Message visible={requestSent && !requestSucceeded} error header="Request Failed" content={errorMessage} />
          <Button positive fluid>
            Send Request
          </Button>
        </Form>
        <LinkButton to="/login" fluid>
          Login
        </LinkButton>
      </CenteredPanel>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      redirectToLogin: false,
      requestSent: false,
      requestSucceeded: false,
      errorMessage: null,
      email: '',
    };
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value, requestSent: false });
  }

  sendRequest() {
    const { email } = this.state;
    AUTH_SERVICE.requestPasswordReset({ email })
      .then(() => this.setState({ requestSent: true, requestSucceeded: true }))
      .then(() => setTimeout(() => this.setState({ redirectToLogin: true }), 2000))
      .catch((err) =>
        this.setState({
          requestSent: true,
          requestSucceeded: false,
          errorMessage: err.message,
        })
      );
  }
}
