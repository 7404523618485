import React, { Component } from 'react';
import { Form, Input, Header, Button, Segment, Loader } from 'semantic-ui-react';
import { Chip } from '../HelperComponents/Chip';
import { MessageType } from '../HelperComponents/DismissibleMessage';
import { ModelConstants } from '../../Helpers/ModelConstants';
import { API_SERVICE } from '../../Services';
import { CheckAdmin } from '../UserManagement/CheckAdmin';

export class PlaceFilter extends Component {
  render() {
    const { serviceContext } = this.props;
    const { loading, blacklistInput, keywordsBlackList, whitelistInput, keywordsWhiteList } = this.state;

    return (
      <div className="ui form">
        <CheckAdmin serviceContext={serviceContext} />
        <Loader active={loading} inline="centered" />
        {!loading && (
          <div>
            <Header>Keywords to remove from the search results</Header>

            <Form.Field>
              <label>Add new keywords</label>
              <Input
                value={blacklistInput}
                onChange={(event, data) => this.handleOnChange('blacklistInput', data.value)}
                onKeyUp={(e) => this.onKeyUp(e, this.addToBlackList)}
              />
            </Form.Field>
            <Segment className="chip-segment">
              {keywordsBlackList &&
                keywordsBlackList
                  .sort(this.comparator)
                  .map((x, i) => (
                    <Chip key={i} onClose={() => this.removeFromBlackList(x)} content={x} style={{ marginRight: '5px' }} />
                  ))}
            </Segment>
            <Header>Keywords to keep in the search results</Header>
            <Form.Field>
              <label>Add new keywords</label>
              <Input
                value={whitelistInput}
                onChange={(event, data) => this.handleOnChange('whitelistInput', data.value)}
                onKeyUp={(e) => this.onKeyUp(e, this.addToWhiteList)}
              />
            </Form.Field>
            <Segment className="chip-segment">
              {keywordsWhiteList &&
                keywordsWhiteList
                  .sort(this.comparator)
                  .map((x, i) => (
                    <Chip key={i} onClose={() => this.removeFromWhiteList(x)} content={x} style={{ marginRight: '5px' }} />
                  ))}
            </Segment>
            <Button onClick={this.reset.bind(this)}>Reset</Button>
            <Button positive onClick={this.save.bind(this)}>
              Save
            </Button>
          </div>
        )}
      </div>
    );
  }

  constructor(props) {
    super(props);

    this.defaultState = {
      blacklistInput: '',
      whitelistInput: '',
    };
    this.state = {
      loading: true,
    };
    Object.assign(this.state, this.defaultState);

    API_SERVICE.doGet(`/${ModelConstants.PLACE_FILTER}/current`).then((config) => {
      this.config = config;
      this.setState({
        loading: false,
        keywordsBlackList: config.keywordsBlackList,
        keywordsWhiteList: config.keywordsWhiteList,
      });
    });
  }

  componentWillUnmount() {
    // Make sure to dismiss the message when the component is unmounted.
    this.props.message({
      visible: false,
    });
  }

  removeFromWhiteList(keyword) {
    this.setState({
      keywordsWhiteList: this.state.keywordsWhiteList.filter((x) => x !== keyword),
    });
  }

  removeFromBlackList(keyword) {
    this.setState({
      keywordsBlackList: this.state.keywordsBlackList.filter((x) => x !== keyword),
    });
  }

  onKeyUp(e, caller) {
    e.preventDefault();
    if (e.keyCode === 13) {
      caller.call(this, e.target.value);
    }
  }

  handleOnChange(name, value) {
    this.setState({ [name]: value });
  }

  addToWhiteList(keyword) {
    if (this.state.keywordsWhiteList.filter((x) => x.toLowerCase() === keyword.toLowerCase()).length > 0) {
      return;
    }
    this.setState({
      keywordsWhiteList: [...this.state.keywordsWhiteList, keyword],
    });
    this.clear('whitelistInput');
  }

  addToBlackList(keyword) {
    if (this.state.keywordsBlackList.filter((x) => x.toLowerCase() === keyword.toLowerCase()).length > 0) {
      return;
    }
    this.setState({
      keywordsBlackList: [...this.state.keywordsBlackList, keyword],
    });
    this.clear('blacklistInput');
  }

  clear(name) {
    this.setState({ [name]: '' });
  }

  save() {
    API_SERVICE.doPost(`/${ModelConstants.PLACE_FILTER}/update`, {
      ...this.config,
      keywordsBlackList: this.state.keywordsBlackList,
      keywordsWhiteList: this.state.keywordsWhiteList,
    })
      .then((config) => {
        this.config = config;
        this.showMessage('Settings successfully saved.', MessageType.SUCCESS);
      })
      .catch((error) => {
        console.error(error);
        this.showMessage('Something went wrong, tray again later.', MessageType.ERROR);
      });
  }

  reset() {
    this.setState({
      ...this.defaultState,
      keywordsBlackList: this.config.keywordsBlackList,
      keywordsWhiteList: this.config.keywordsWhiteList,
    });
    this.showMessage('Settings was reset.', MessageType.INFO);
  }

  showMessage(message, type) {
    this.props.message({
      [type]: true,
      visible: true,
      content: message,
    });
  }

  comparator(o1, o2) {
    const left = o1.toLowerCase();
    const right = o2.toLowerCase();
    if (left > right) {
      return 1;
    }
    if (left < right) {
      return -1;
    }
    return 0;
  }
}
