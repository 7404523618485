import { SORT_ASC, SORT_DESC, SORT_UI_ASC } from './Constants';

export const singleLineString = (strings, ...values) => {
  // Interweave the strings with the
  // substitution vars first.
  let output = '';
  for (let i = 0; i < values.length; i++) {
    output += strings[i] + values[i];
  }
  output += strings[values.length];

  // Split on newlines.
  let lines = output.split(/(?:\r\n|\n|\r)/);

  // Rip out the leading whitespace.
  return lines
    .map((line) => {
      return line.replace(/^\s+/gm, '');
    })
    .join(' ')
    .trim();
};

export const roundup = (float, decimal) => {
  return parseFloat(Math.round(float * Math.pow(10, decimal)) / Math.pow(10, decimal)).toFixed(decimal);
};

export const toLocalDate = (dateTime) => {
  return new Date(dateTime).toLocaleDateString();
};

export const addDays = (date, days) => {
  const newDate = new Date(date.valueOf());
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

export const toSearchQueryMap = (searchQuery) => {
  const map = new Map();
  const sq = searchQuery.replace('?', '');
  const pairList = sq.split('&');
  pairList.forEach((x) => {
    const pair = x.split('=');
    if (pair.length > 1) {
      map.set(pair[0].toLowerCase(), pair[1].toLowerCase());
    }
  });
  return map;
};

export const loadDefaultActiveIndex = (searchQuery, keys) => {
  const defaultActiveIndex = [];
  const searchQueryMap = toSearchQueryMap(searchQuery);

  keys.forEach((key) => {
    if (searchQueryMap.has(key.value) && searchQueryMap.get(key.value) === 'true') {
      defaultActiveIndex.push(key.index);
    }
  });

  return defaultActiveIndex;
};

export const toWhereClause = (conditions) => {
  const logic = 'or';
  return conditions
    .map((x, i) => {
      switch (x.type) {
        case 'number':
        case 'boolean':
          return `filter[where][${logic}][${i}][${x.property}]=${x.value}`;
        case 'string':
        default:
          return `filter[where][${logic}][${i}][${x.property}][regexp]=/${x.value}/i`;
      }
    })
    .join('&');
};

export const toSortOrder = (sortUiOrder) => {
  return sortUiOrder === SORT_UI_ASC ? SORT_ASC : SORT_DESC;
};
