import React, { Component } from 'react';
import { Input } from 'semantic-ui-react';

export class Filter extends Component {
  render() {
    const { floated, onChange, placeholder, fluid } = this.props;

    return (
      <Input
        style={{ float: floated, width: '300px' }}
        placeholder={placeholder}
        fluid={fluid}
        onChange={(event, data) => onChange(data)}
      />
    );
  }
}
