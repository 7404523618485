import React from 'react';
import ReactDOM from 'react-dom';

import { Config } from '../../../Config';

import { DialogDetails } from './DialogDetails';
import { Eraser } from './Eraser';
import InfoWindow from './InfoWindow';
import { Legend, Color } from './Legend';
import { Wizard } from './Wizard';

import { METER_TO_MILE, MILE_TO_DEGREE } from '../../../Helpers/Constants';
import { GetBatchQuery } from '../../../Helpers/Query';
import { filterStores } from '../../../Helpers/Filter';
import { Vector2, inflatePolygon } from '../../../Helpers/Topology';
import { roundup } from '../../../Helpers/Utilities';

import { MessageType } from '../../HelperComponents/DismissibleMessage';

import { CARTO_SERVICE, PLACES_SERVICE } from '../../../Services';

import './Batch.css';

class Batch extends React.Component {
  render() {
    return (
      <div className="batch">
        <Wizard
          onMapExtentClick={() => this.openDialog()}
          onDrawingClick={() => this.startDrawing()}
          onTaskRecoverClick={() => this.recover()}
          progress={this.state.progress}
          minimized={this.state.minimized}
        />
        <Eraser clearPoints={() => this.clear()} />
        <Legend />
        <DialogDetails
          openDialog={this.state.openDialog}
          offSetOptions={this.options}
          defaultOffSet={1}
          offsetOnChange={(event, data) => this.onOffsetChange(data.value)}
          squareFootageOnChange={(e) => this.updateSquareFootage(e.target.value)}
          squareFootageValue={this.state.squareFootage}
          started={this.state.started}
          progress={this.state.progress}
          remainingTime={this.formatTime(this.state.remainingTime)}
          isResultOver60={this.state.isOver60}
          cancel={() => this.cancel()}
          start={() => this.start()}
          minimize={() => this.minimize()}
        />
      </div>
    );
  }

  constructor(props) {
    super(props);

    this.options = [
      { value: 1, text: '1 mile' },
      { value: 2, text: '2 miles' },
      { value: 3, text: '3 miles' },
    ];

    this.defaultState = {
      remainingTime: '',
      squareFootage: 55000,
      openDialog: false,
      minimized: false,
      offset: this.options[0].value * METER_TO_MILE,
      batchSize: 0,
    };

    this.state = {
      remainingTime: null,
      stores: [],
      isOver60: false,
      squareFootage: null,
      openDialog: null,
      minimized: null,
      offset: null,
      batchSize: 0,
    };

    Object.assign(this.state, this.defaultState);
    this.points = [];
    this.completed = 0;
    this.cachedStores = [];
    this.cancelledTotal = 0;
  }

  openDialog() {
    this.setState(
      {
        openDialog: true,
        squareFootage: this.defaultState.squareFootage,
      },
      () => {
        this.updateSquareFootage();
      }
    );
  }

  closeDialog() {
    this.setState({
      openDialog: false,
      started: false,
    });
  }

  showMessage(message, type) {
    // calls Map.updateMessage, passed as `message` property
    this.props.message({
      [type]: true,
      visible: true,
      content: message,
    });
    this.setState({ minimized: false });
  }

  updateSquareFootage(input) {
    const value = isNaN(+input) ? this.defaultState.squareFootage : +input;
    this.setState({
      squareFootage: value,
    });
  }

  cancel() {
    if (this.state.started) {
      this.setState({
        started: false,
        stores: [],
        isOver60: false,
        remainingTime: this.defaultState.remainingTime,
      });
      // TODO - cancel CARTO query(ies)
      // TODO - cancel places API query(ies)
      this.showMessage('Requests canceled.', MessageType.INFO);
    } else {
      this.clearPolygon();
      this.closeDialog();
    }
  }

  start() {
    this.completed = 0;
    this.cancelledTotal = 0;
    this.startTime = Date.now();
    this.setState(
      {
        started: true,
        isGettingDetails: false,
        progress: 0,
      },
      () => {
        try {
          this.setState(
            {
              isGettingDetails: true,
            },
            async () => {
              this.clearPoints();
              if (this.polygon) {
                this.outwardPolygon = this.outwardPolygonOffset(
                  this.polygon,
                  (MILE_TO_DEGREE * this.state.offset) / METER_TO_MILE
                );
              }
              await this.createOffsetPoints(true);
            }
          );
        } catch (error) {
          this.showMessage('Something went wrong, try again later.', MessageType.ERROR);
          this.closeDialog();
          console.error(error);
        }
      }
    );
  }

  onOffsetChange(value) {
    this.setState({ offset: value * METER_TO_MILE });
  }

  async createOffsetPoints(searchNearby) {
    const bounds = this.outwardPolygon ? this.getDrawingBounds(this.outwardPolygon) : this.props.map.getBounds();

    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();
    const se = new this.props.gmaps.LatLng(sw.lat(), ne.lng());
    const nw = new this.props.gmaps.LatLng(ne.lat(), sw.lng());
    const width = this.props.gmaps.geometry.spherical.computeDistanceBetween(sw, se);
    const height = this.props.gmaps.geometry.spherical.computeDistanceBetween(sw, nw);

    const widthFraction = width / this.state.offset;
    const heightFraction = height / this.state.offset;

    const widthDimension = Math.ceil(widthFraction) + 1;
    const heightDimension = Math.ceil(heightFraction) + 1;
    const matrixSize = widthDimension * heightDimension;

    if (matrixSize > Config.maxOffsetPoints) {
      return this.handleTooManyPoints();
    }

    const viewportPoints = [];
    for (let i = 0; i < heightDimension; i++) {
      const firstCoordsInRow = this.props.gmaps.geometry.spherical.interpolate(sw, nw, i / heightFraction);
      const lastCoordsInRow = new this.props.gmaps.LatLng(firstCoordsInRow.lat(), ne.lng());
      for (let j = 0; j < widthDimension; j++) {
        const point = this.props.gmaps.geometry.spherical.interpolate(firstCoordsInRow, lastCoordsInRow, j / widthFraction);
        viewportPoints.push(point);
      }
    }
    const batchPoints = this.outwardPolygon ? this.extractPointsInPolygon(viewportPoints, this.outwardPolygon) : viewportPoints;
    this.setState({ batchSize: batchPoints.length });

    let parallelRequestLimit = Config.batchParallelism;
    let promises = [];
    while (batchPoints.length > 0) {
      // handle cancelation
      if (!this.state.started) {
        promises = [];
        break;
      }

      const [point] = batchPoints.splice(0, 1);
      const promise = this.queryDetails(point, searchNearby);
      promises.push(promise);

      while (promises.length >= parallelRequestLimit) {
        // wait for at least one request to finish
        await Promise.race(promises);

        // filter the queue to only contain still-pending promises
        promises = (
          await Promise.all(
            promises.map(async (p) => {
              // if promise is still unsettled, `race()` will return the second 'pending' promise
              const pending = await Promise.race([p, Promise.resolve('pending')]);
              return pending === 'pending' ? p : null;
            })
          )
        ).filter((p) => p !== null);
      }
    }
    // wait for remaining to finish
    await Promise.all(promises);
  }

  getDrawingBounds(polygon) {
    const Circle = this.props.gmaps.Circle;
    const Rectangle = this.props.gmaps.Rectangle;
    const Polygon = this.props.gmaps.Polygon;

    if (polygon instanceof Circle || polygon instanceof Rectangle) {
      return polygon.getBounds();
    }

    if (polygon instanceof Polygon) {
      var bounds = new this.props.gmaps.LatLngBounds();
      polygon.getPath().forEach((latlng) => bounds.extend(latlng));
      return bounds;
    }

    return null;
  }

  extractPointsInPolygon(viewportPoints, polygon) {
    const Circle = this.props.gmaps.Circle;
    const Rectangle = this.props.gmaps.Rectangle;
    const Polygon = this.props.gmaps.Polygon;

    const geometry = this.props.gmaps.geometry;

    if (polygon instanceof Circle) {
      return viewportPoints.filter(
        (x) => geometry.spherical.computeDistanceBetween(x, polygon.getCenter()) <= polygon.getRadius()
      );
    }

    if (polygon instanceof Rectangle) {
      return viewportPoints.filter((x) => polygon.getBounds().contains(x));
    }

    if (polygon instanceof Polygon) {
      return viewportPoints.filter((x) => geometry.poly.containsLocation(x, polygon));
    }

    return [];
  }

  handleTooManyPoints() {
    this.clear();
    this.showMessage(
      'The search area is too large. Zoom in to a smaller area or draw a smaller shape and try again.',
      MessageType.WARNING
    );
    this.closeDialog();
  }

  drawPoint(position, color, info, score, stores) {
    const point = new this.props.gmaps.Marker({
      map: this.props.map,
      position: position,
      icon: {
        path: this.props.gmaps.SymbolPath.CIRCLE,
        scale: 6,

        fillColor: color,
        fillOpacity: 1.0,

        strokeColor: '#000000',
        strokeOpacity: 0.33,
        strokeWeight: 2,
      },
    });

    point.addListener('click', () => this.showLocationDetails(point, info, score, stores));
    this.points.push(point);
  }

  clearPoints() {
    this.points.forEach((point) => point.setMap(null));
    this.points = [];
    // this.props.clear()
  }

  showLocationDetails(point, info, score, stores) {
    if (!this.locationPopup) {
      this.locationPopup = new this.props.gmaps.InfoWindow();
    }
    if (!this.infoContent) {
      this.infoContent = document.createElement('div');
    }

    ReactDOM.render(
      <InfoWindow
        info={info}
        score={score}
        stores={stores}
        search={this.props.search}
        point={point.getPosition()}
        popup={this.locationPopup}
      />,
      this.infoContent
    );

    this.locationPopup.setOptions({
      content: this.infoContent,
    });
    this.locationPopup.open(this.props.map, point);
  }

  queryDetails(position, searchNearby) {
    return this.query(position)
      .then(async (response) => {
        let neabyStores;
        if (searchNearby) {
          try {
            neabyStores = await this.searchNearby(position);
          } catch (ex) {
            console.warn(ex);
            neabyStores = [];
          }
        } else {
          neabyStores = this.state.stores;
        }

        const stores = filterStores(neabyStores, position, 3 * METER_TO_MILE);

        if (!(response && response.rows && response.rows[0])) {
          console.error('Bad response!!!', response);
        }

        const info = (response && response.rows && response.rows[0]) || {};

        const score = roundup((stores.length * this.state.squareFootage) / info.raw_population_2020, 2);
        if (score < 1) {
          this.drawPoint(position, Color.BLUE, info, score, stores);
        } else if (score < 3) {
          this.drawPoint(position, Color.CYAN, info, score, stores);
        } else if (score < 5) {
          this.drawPoint(position, Color.GREEN, info, score, stores);
        } else if (score < 7) {
          this.drawPoint(position, Color.YELLOW, info, score, stores);
        } else if (score < 9) {
          this.drawPoint(position, Color.ORANGE, info, score, stores);
        } else if (score < 11) {
          this.drawPoint(position, Color.RED, info, score, stores);
        } else if (score < 13) {
          this.drawPoint(position, Color.MAGENTA, info, score, stores);
        } else {
          this.drawPoint(position, Color.PURPLE, info, score, stores);
        }

        this.calcProgress();
      })
      .catch((reason) => {
        this.cancelledTotal++;
        console.error('request canceled', reason);
        this.drawPoint(position, Color.WHITE, {}, Config.canceled, []);
        this.calcProgress();
      });
  }

  calcProgress() {
    this.completed++;
    const timePerQuery = (Date.now() - this.startTime) / this.completed;
    const estTotalTime = timePerQuery * this.state.batchSize;
    this.setState(
      {
        progress: (this.completed / this.state.batchSize) * 100,
        remainingTime: Math.round((estTotalTime - timePerQuery * this.completed) / 1000),
      },
      () => {
        if (this.state.remainingTime > 0) {
          if (this.remaingTimer) {
            clearInterval(this.remaingTimer);
          }
          this.remaingTimer = setInterval(() => {
            if (this.state.remainingTime > 1) {
              this.setState({
                remainingTime: this.state.remainingTime - 1,
              });
            }
          }, 1000);
        }
        if (this.state.progress === 100) {
          this.onQueryComplete();
        }
      }
    );
  }

  onQueryComplete() {
    this.closeDialog();
    if (this.remaingTimer) {
      clearInterval(this.remaingTimer);
    }
    this.clearPolygon();
    this.setState(
      {
        remainingTime: this.defaultState.remainingTime,
      },
      () =>
        this.showMessage(`Successfully analyzed ${this.state.batchSize - this.cancelledTotal} locations.`, MessageType.SUCCESS)
    );
  }

  query(p) {
    const queryString = GetBatchQuery(p.lat(), p.lng(), 3 * METER_TO_MILE);
    return CARTO_SERVICE.sql(queryString);
  }

  minimize() {
    this.setState({
      minimized: true,
      openDialog: false,
    });
  }

  recover() {
    this.setState({
      minimized: false,
      openDialog: true,
    });
  }

  searchNearby(position) {
    let cachedStores = [];

    return PLACES_SERVICE.nearbySearch({
      location: {
        lat: position.lat(),
        lng: position.lng(),
      },
      radius: 3 * METER_TO_MILE,
      keyword: 'self-storage facilities',
      type: 'storage',
    })
      .then((result) => {
        cachedStores = cachedStores.concat(result);
        return cachedStores;
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  }

  adjustTime(timeDifference, cb) {
    this.setState({ remainingTime: this.state.remainingTime + timeDifference }, cb);
  }

  formatTime(seconds) {
    if (this.state.progress > 0) {
      if (seconds < 60) {
        return 'less than a minute';
      } else {
        return `about ${Math.ceil(seconds / 60)} minutes`;
      }
    } else {
      return 'Calculating...';
    }
  }

  drawingComplete(shape) {
    this.polygon = shape;
    this.polygon.setOptions({
      fillOpacity: 0,
      strokeColor: Config.drawPolygonStroke,
    });
    this.drawingManager.setMap(null);
    this.openDialog();
  }

  escapeDrawing(evt) {
    evt = evt || window.event;
    var isEscape = !1;
    isEscape = 'key' in evt ? 'Escape' === evt.key || 'Esc' === evt.key : 27 === evt.keyCode;
    if (isEscape) {
      this.drawingManager.setDrawingMode(null);
      this.drawingManager.setMap(null);
    }
  }

  startDrawing() {
    const { map, gmaps } = this.props;
    if (!this.drawingManager) {
      var polygonOptions = {
        fillColor: Config.drawPolygonFill,
        strokeColor: Config.drawPolygonStroke,
        zIndex: -1,
      };

      this.drawingManager = new gmaps.drawing.DrawingManager({
        drawingMode: gmaps.drawing.OverlayType.RECTANGLE,
        drawingControlOptions: {
          drawingModes: [
            gmaps.drawing.OverlayType.POLYGON,
            gmaps.drawing.OverlayType.CIRCLE,
            gmaps.drawing.OverlayType.RECTANGLE,
          ],
          position: gmaps.ControlPosition.TOP_CENTER,
        },
        circleOptions: polygonOptions,
        rectangleOptions: polygonOptions,
        polygonOptions: polygonOptions,
      });

      this.drawingManager.addListener('polygoncomplete', this.drawingComplete.bind(this));
      this.drawingManager.addListener('circlecomplete', this.drawingComplete.bind(this));
      this.drawingManager.addListener('rectanglecomplete', this.drawingComplete.bind(this));
    }

    this.clear();
    this.drawingManager.setMap(map);

    document.onkeydown = this.escapeDrawing.bind(this);
  }

  clearPolygon() {
    if (this.polygon) {
      this.polygon.setMap(null);
      this.polygon = undefined;
    }
    if (this.outwardPolygon) {
      this.outwardPolygon.setMap(null);
      this.outwardPolygon = undefined;
    }
  }

  clear() {
    this.clearPolygon();
    this.clearPoints();
  }

  outwardPolygonOffset(polygon, spacing) {
    const LatLng = this.props.gmaps.LatLng;
    const Polygon = this.props.gmaps.Polygon;
    const Rectangle = this.props.gmaps.Rectangle;
    const Circle = this.props.gmaps.Circle;

    if (polygon instanceof Circle) {
      return new Circle({
        center: polygon.getCenter(),
        radius: polygon.getRadius() + this.state.offset,
        map: polygon.getMap(),
        fillColor: Config.drawPolygonFill,
        strokeColor: Config.drawPolygonStroke,
        zIndex: -1,
      });
    }

    if (polygon instanceof Rectangle) {
      const bounds = polygon.getBounds();
      const sw = bounds.getSouthWest();
      const ne = bounds.getNorthEast();

      const vectors = [
        new Vector2(sw.lng(), sw.lat()),
        new Vector2(sw.lng(), ne.lat()),
        new Vector2(ne.lng(), ne.lat()),
        new Vector2(ne.lng(), sw.lat()),
      ];

      const outwardVectors = inflatePolygon(vectors, spacing);
      for (const vector of outwardVectors) {
        bounds.extend(new LatLng(vector.y, vector.x));
      }

      return new Rectangle({
        bounds: bounds,
        map: polygon.getMap(),
        fillColor: Config.drawPolygonFill,
        strokeColor: Config.drawPolygonStroke,
        zIndex: -1,
      });
    }

    if (polygon instanceof Polygon) {
      const vectors = polygon
        .getPath()
        .getArray()
        .map(function (latlng) {
          return new Vector2(latlng.lng(), latlng.lat());
        });

      const outwardVectors = (0, inflatePolygon)(vectors, spacing),
        path = outwardVectors.map(function (vector) {
          return new LatLng(vector.y, vector.x);
        });

      return new Polygon({
        paths: path,
        map: polygon.getMap(),
        fillColor: Config.drawPolygonFill,
        strokeColor: Config.drawPolygonStroke,
        zIndex: -1,
      });
    }
  }
}

export default Batch;
