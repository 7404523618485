import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AUTH_SERVICE } from '../../Services';

export const RouteWithSubRoutes = (props) => (
  <Route
    path={props.path}
    exact={props.exact}
    render={(routeProps) =>
      AUTH_SERVICE.isAuthenticated || props.public ? (
        <props.component
          {...routeProps}
          name={props.name}
          message={props.message}
          routes={props.routes}
          serviceContext={props.serviceContext}
        />
      ) : (
        <Redirect to={{ pathname: '/login', state: { from: routeProps.location } }} />
      )
    }
  />
);
