import { ModelConstants } from '../Helpers/ModelConstants';
import { AbstractDaoService } from './AbstractDaoService';

export class UserService extends AbstractDaoService {
  constructor(apiService) {
    super(apiService, `/${ModelConstants.APP_USER}`, 'filter[include]=organization');
  }

  findAllSimplified() {
    return this.findAll().then((appUsers) => this._simplifyAppUsers(appUsers));
  }

  findAllPaginatedAndSortedSimplified(page, size, sortBy, sortOrder) {
    return this.findAllPaginatedAndSorted(page, size, sortBy, sortOrder).then((appUsers) => this._simplifyAppUsers(appUsers));
  }

  findMatchPaginatedAndSortedSimplified(conditions, page, size, sortBy, sortOrder) {
    return this.findMatchPaginatedAndSorted(conditions, page, size, sortBy, sortOrder).then((appUsers) =>
      this._simplifyAppUsers(appUsers)
    );
  }

  findOne(id) {
    return super.findOne(id, 'filter[include]=organization');
  }

  update(id, resource) {
    return this._apiService.doPost(`${this._base}/update?where={"id":${id}}`, resource);
  }

  _simplifyAppUsers(appUsers) {
    return appUsers.map((appUser) => ({
      id: appUser.id,
      firstName: appUser.firstName,
      lastName: appUser.lastName,
      email: appUser.email,
      organization: appUser.organization ? appUser.organization.name : null,
    }));
  }
}
