import React, { Component } from 'react';
import { Message } from 'semantic-ui-react';

export const MessageType = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
};

export class DismissibleMessage extends Component {
  render() {
    if (!this.state.visible) {
      return null;
    }
    // <Message/> seems spread all its props to its children. This will cause an
    // error if any invalid props are passed down to the children.
    // Prevent `updateMessage` prop passed down to the children of <Message/> by
    // removing `updateMessage` from the shallow copy of the props and assign it
    // to the <Message/>
    const props = { ...this.props };
    delete props.updateMessage;
    return <Message {...props} icon={this.mapIcon()} onDismiss={() => this.handleDismiss()} />;
  }

  constructor(props) {
    super(props);
    this.state = { visible: props.visible || false };
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.visible) {
      this.setState({ visible: false });
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
    } else if (nextProps.visible !== this.state.visible) {
      this.setState({ visible: nextProps.visible });
      this.timeout = setTimeout(() => {
        this.setState({ visible: false });
        this.props.updateMessage({});
      }, 5000);
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  handleDismiss() {
    this.setState({ visible: false }, this.props.onDismiss);
    this.props.updateMessage({});
  }

  mapIcon() {
    if (this.props.info) {
      return 'info';
    }
    if (this.props.warning) {
      return 'warning';
    }
    if (this.props.success) {
      return 'check';
    }
    if (this.props.error) {
      return 'warning circle';
    }
  }
}
